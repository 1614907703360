import styled from 'styled-components';

import * as set from '../../style/Variables/StyleSet';
import * as color from '../../style/Variables/Colors';
import * as font from '../../style/Variables/Fonts';

export const Button = styled.button`    
    font-size: ${props => props.icon_button ? font.titleFontSize : font.defaultFontSize};
    padding: ${props => props.icon_button ? "0" : "6px 15px 7px"};
    min-width: ${props => props.text_button ? "80px" : props.icon_text_button ? "80px" : props.text_icon_button ? "80px" : "auto"};
    width: ${props => props.icon_button ? "30px" : "auto"};

    ${set.defaultBtn}
    ${set.radius60px}
    ${set.defaultShadow}
    ${set.transitionAll}
    ${set.setBorderBgdColor}
    
    svg {
        vertical-align: bottom;
    }

    &.facebook { background: #3b5998; color: ${color.white}; border: #3b5998 1px solid; }
    &.google { background: #d34836; color: ${color.white}; border: #d34836 1px solid;
        i {
            font-size: ${font.lFontSize};
            vertical-align: middle;
            line-height: 0;
        }
    }

    &:not(:last-child) { margin-right: 5px; }
    &:focus {
        ${set.hardShadow}
    }
`;

export const IconButtonSimple = styled.button`
    background: transparent;
    color: ${color.grey};
    font-size: ${font.titleFontSize};
    line-height: 1;
    display: inline-block;
    position: relative;
    padding: 0;
    margin-bottom: 10px;
    height: 40px;
    white-space: nowrap;
    border: none;

    ${set.transitionAll}
    ${set.setColor}

    &.right { float: right; }
    &:not(:last-child) { margin-right: 5px; }
    &:focus { color: ${color.primaryColor}; ${set.noShadow} }  
`;

export const Dropdown = styled.div`
    display: -webkit-inline-box;
    position: relative;
    padding-left: ${props => props.user_dropdown ? "5px" : "0"};
    width: ${props => props.ticket_dropdown ? "100%" : ""};

    .dropdown_menu {
        display: none;
        position: absolute;
        background: ${color.white};
        z-index: 9;
        top: 40px;
        width: 100%;
        min-width: 200px;
        border: 1px solid #ccc;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: 4px;

        ${set.hardShadow}

        &.dropdown_menu_noti {
            min-width: 300px;
            border: none;
        }
        .dropdown_before:before {
            content: '';
            height: 20px;
            border-left: 14px solid transparent;
            border-right: 14px solid transparent;
            border-bottom: 8px solid #ccc;
            position: absolute;
            top: -20px;
        }
        .dropdown_before:after {
            content: '';
            height: 18px;
            border-left: 13px solid transparent;
            border-right: 13px solid transparent;
            border-bottom: 7px solid #fff;
            position: absolute;
            top: -18px;
        }
        .dropdown_list {
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: ${font.sFontSize};
            text-align: left;
            
            .dropdown_item {
                &:not(:last-child) { border-bottom: solid 1px ${color.greyLight}; }

                &.dropdown_item_noti:first-child .dropdown_link { ${set.radius4pxTop} }

                .dropdown_link {
                    ${set.noWrap}
                    padding: ${props => props.user_dropdown ? "10px" : "7px 10px"};
                    display: block;
                    cursor: pointer;
                    color: ${color.grey};
                    line-height: 22px;

                    &.notification {
                        display: table;
                        width: 100%;
                        white-space: normal;
                        padding: 10px;
                    }

                    ${set.setBgdLight}

                    span {
                        padding-right: 5px;
                        font-size: ${font.mFontSize};

                        ${set.setColor}
                    }
                    .noti_icon {
                        display: table-cell;
                        vertical-align: middle;
                        font-size: ${font.xxlFontSize};
                        padding-right: 5px;
                        width: 40px;

                        ${set.setColor}
                    }
                    .noti_info {
                        display: table-cell;
                        vertical-align: middle;
                    }
                    .noti_title {
                        font-size: ${font.defaultFontSize};
                        font-weight: 400;
                        line-height: 1;
                    }
                    .noti_desc {
                        font-size: ${font.sFontSize};
                        line-height: 1.2;
                        padding: 2px 0;
                    }
                    .noti_desc.activity {
                        span {
                            width: 10px;
                            height: 10px;
                            display: inline-block;
                            margin: 0;
                            ${set.radius100} 
            
                            &.primary { background-color: ${color.primaryColor}; }
                            &.success { background-color: ${color.success}; }
                            &.warning { background-color: ${color.warning}; }
                            &.danger { background-color: ${color.danger}; }
                            &.grey { background-color: ${color.grey}; }
                        }
                    }
                    .noti_time {
                        font-size: ${font.xxsFontSize};
                        line-height: 1;
                        text-align: right;
                    }
                }
                .dropdown_link:hover {
                    background: ${color.greyLight};

                    ${set.setBgd}

                    &.white { 
                        .noti_icon {
                            color: ${color.black};
                        }
                    }
                    .noti_icon {
                        color: ${color.white};
                    }
                }
                &.notification_more {
                    text-align: center;
                    padding: 8px;
                    cursor: pointer;
                }
            }
        }
        .dropdown_list_noty {
            max-height: 70vh;
            overflow-y: auto;

            ${set.setScrollStyle}
        }
        &.dropdown_active {
            display: block;
            z-index: 99;
        }
    }
    &.left {
        .dropdown_menu { left: 0; }
        .dropdown_before:before { left: 0; }
        .dropdown_before:after { left: 1px; }
    }
    &.right {
        .dropdown_menu { right: 0; }
        .dropdown_before:before { right: 0; }
        .dropdown_before:after { right: 1px; }
    }
    &:focus { ${set.hardShadow} }
    .noty_bubble{
        width: 15px;
        height: 15px;
        font-size: 9px;
        line-height: 15px;
        display: block;
        background-color: ${color.danger};
        color: ${color.white};
        text-align: center;
        position: absolute;
        top: -3px;
        right: -3px;

        ${set.radius100}
    }
`;

export const NotificationDropdown = styled.div`
    display: block;
    position: relative;
    margin-bottom: 5px;
    padding-left: ${props => props.user_dropdown ? "5px" : "0"};

    .dropdown_menu {
        display: none;
        position: absolute;
        background: #fff;
        z-index: 9;
        bottom: 5px;
        left: 65px !important;
        width: 100%;
        min-width: 200px;
        border: 1px solid rgba(0,0,0,.15);

        ${set.radius6px}
        ${set.hardShadow}

        &.dropdown_menu_noti {
            min-width: 300px;
        }
        .dropdown_before:before {
            content: '';
            height: 20px;
            border-style: solid;
            border-width: 11px 10px 11px 0;
            border-color: transparent #ccc transparent transparent;
            position: absolute;
            left: -10px !important;
            bottom: 1px;
        }
        .dropdown_before:after {
            content: '';
            height: 18px;
            border-style: solid;
            border-width: 10px 9px 10px 0;
            border-color: transparent ${color.white} transparent transparent;
            position: absolute;
            left: -9px !important;
            bottom: 2px;
        }
        .dropdown_list {
            max-height: 510px;
            list-style: none;
            margin: 0;
            padding: 0;
            font-size: ${font.sFontSize};
            text-align: left;
            ${set.radius6px}
            
            .dropdown_item {
                &:not(:last-child) { border-bottom: solid 1px ${color.greyLight}; }
                &.dropdown_item_noti:not(:last-child) { border-bottom: none }
                .dropdown_link {
                    ${set.noWrap}
                    padding: ${props => props.user_dropdown ? "10px" : "7px 10px"};
                    display: block;
                    cursor: pointer;
                    color: ${color.grey};

                    &.notification {
                        display: table;
                        width: 100%;
                        white-space: normal;
                        padding: 10px;
                    }

                    ${set.setBgdBGD}

                    span {
                        padding-right: 5px;
                        font-size: ${font.mFontSize};
                    }
                    .noti_icon {
                        display: table-cell;
                        vertical-align: middle;
                        font-size: ${font.xxlFontSize};
                        padding-right: 5px;

                        ${set.setColor}
                    }
                    .noti_title {
                        font-size: ${font.defaultFontSize};
                        font-weight: 400;
                        line-height: 1;
                    }
                    .noti_desc {
                        font-size: ${font.sFontSize};
                        line-height: 1.2;
                        padding: 5px 0;
                    }
                    .noti_time {
                        font-size: ${font.xxsFontSize};
                        line-height: 1;
                    }
                }
                .dropdown_link:hover {
                    background: ${color.greyLight};
                    ${set.setBgdColor}

                    &.white { 
                        .noti_icon {
                            color: ${color.black};
                        }
                    }
                    .noti_icon {
                        color: ${color.white};
                    }
                }
            }
        }
        &.dropdown_active {
            display: block;
            z-index: 99;
        }
    }
    &.left {
        .dropdown_menu { left: 0; }
        .dropdown_before:before { left: 0; }
        .dropdown_before:after { left: 1px; }
    }
    &.right {
        .dropdown_menu { right: 0; }
        .dropdown_before:before { right: 0; }
        .dropdown_before:after { right: 1px; }
    }
`;

export const NotificationDropdownButton = styled.button`
    background: ${color.white};
    color: ${color.white};
    font-size: ${props => props.icon_button ? font.titleFontSize : font.defaultFontSize};
    width: ${props => props.icon_dropdown ? "30px" : "auto"};
    padding: ${props => props.icon_dropdown ? "0" : "0 20px 0 10px"};
    min-width: ${props => props.icon_dropdown ? "30px" : "200px"};
    text-align: ${props => props.icon_dropdown ? "center" : "left"};
    line-height: 1;
    display: inline-block;
    position: relative;
    height: 30px;
    white-space: nowrap;
    border: ${color.grey} 1px solid;

    ${set.defaultShadow}
    ${set.radius60px}
    ${set.transitionAll}
    ${set.setBorderBgdColor}

    &:focus { ${set.hardShadow} }
    svg { 
        vertical-align: bottom;
        float: ${props => props.icon_dropdown ? "none" : "right"};
        width: ${props => props.icon_dropdown ? "auto" : "14px"};
        height: ${props => props.icon_dropdown ? "auto" : "14px"};
    }
`;

export const DropdownButton = styled.button`
    background: ${color.white};
    color: ${color.white};
    font-size: ${props => props.icon_button ? font.titleFontSize : font.defaultFontSize};
    width: ${props => props.icon_dropdown ? "30px" : "auto"};
    padding: ${props => props.icon_dropdown ? "0" : "0 20px 0 10px"};
    min-width: ${props => props.icon_dropdown ? "30px" : "auto"};
    text-align: ${props => props.icon_dropdown ? "center" : "left"};
    line-height: 1;
    display: inline-block;
    position: relative;
    height: 30px;
    white-space: nowrap;
    border: ${color.grey} 1px solid;

    &.full_width {
        display: block;
        width: 100%;
    }

    ${set.defaultShadow}
    ${set.radius60px}
    ${set.transitionAll}
    ${set.setBorderBgdColor}

    &:focus { ${set.hardShadow} }
    svg { 
        vertical-align: middle;
        width: ${props => props.icon_dropdown ? "auto" : "14px"};
        height: ${props => props.icon_dropdown ? "auto" : "14px"};
        float: ${props => props.icon_dropdown ? "none" : "none"};
        position: ${props => props.icon_dropdown ? "initial" : "absolute"};
        right: ${props => props.icon_dropdown ? "auto" : "5px"};
    }
`;

export const TicketDropdownButton = styled.button`
    background: ${color.white};
    color: ${color.white};
    font-size: ${props => props.icon_button ? font.titleFontSize : font.defaultFontSize};
    padding: ${props => props.icon_dropdown ? "0" : "0 20px 0 5px"};
    width: ${props => props.icon_dropdown ? "30px" : "auto"};
    min-width: ${props => props.icon_dropdown ? "30px" : "auto"};
    text-align: ${props => props.icon_dropdown ? "center" : "left"};
    line-height: 1;
    display: inline-block;
    position: relative;
    height: 20px;
    margin: 4px 2px !important;
    white-space: nowrap;
    border: ${color.grey} 1px solid;

    &.full_width {
        display: block;
    }

    ${set.defaultShadow}
    ${set.radius60px}
    ${set.transitionAll}
    ${set.setBorderBgdColor}
    
    &:focus { ${set.hardShadow} }
    svg { 
        vertical-align: middle;
        width: ${props => props.icon_dropdown ? "auto" : "14px"};
        height: ${props => props.icon_dropdown ? "auto" : "14px"};
        float: ${props => props.icon_dropdown ? "none" : "none"};
        position: ${props => props.icon_dropdown ? "initial" : "absolute"};
        right: ${props => props.icon_dropdown ? "auto" : "5px"};
    }
    .dd_btn_text_box {
        display: table;

        .dropdown_text_ellipsis {
            
        }
    }
    .dd_btn_text {
        width: calc(100% - 48px);
    }
    .dd_btn_icon, .dd_btn_text {
        display: inline-block;
    }
`;

export const TabButton = styled.button`
    width: 100%;
    border: 0;
    font-size: ${font.defaultFontSize};
    background: ${color.primaryColorLight};
    color: ${color.white};
    border-right: 1px solid ${color.white};
    padding: 5px 10px;

    ${set.noWrap}
    ${set.transitionAll}

    .la, .las, .lab {
        font-size: ${font.lFontSize};
        vertical-align: middle;
    }
    &:focus { ${set.hardShadow} }
    &.active {
        background: ${color.primaryColor};
    }
    &:last-child {
        border-right: none;
    }
    .noty_dot {
        width: 7px;
        height: 7px;
        margin-left: 3px;
        top: -5px;
        position: relative;
        display: inline-block;
        background-color: ${color.danger};

        ${set.radius100}
    }
`;

export const Switch = styled.div`
    display: inline-block;
    position: relative;
    top: 10px;
    height: 30px;

    label { margin: 0; }
    .react-switch {
        ${set.defaultShadow}
        .react-switch-bg {
            div {
                width: auto !important;
            }
        }
    }
    .react-switch-handle {
        box-shadow: none !important;
    }
    &:focus { ${set.hardShadow} }
`;

export const Pagination = styled.div`
    .pagination {
        text-align: center;
        display: block;
        margin-bottom: 0;

        li  { display: inline-block;
            a {
                background: ${color.white};
                color: ${color.primaryColor};
                font-size: ${font.titleFontSize};
                width: 28px;
                height: 28px;
                text-align: center;
                margin: 0 2px;
                display: block;
                padding: 1px;
                cursor: pointer;

                ${set.radius100} 
                ${set.defaultShadow}

                &:focus { outline: none; }
            }
        }
        li.active a {
            cursor: default;
            background: ${color.primaryColor};
            color: ${color.white};

            ${set.hardShadow}
        }
        li.disabled a {
            background: ${color.greyLight};
            color: ${color.greyMedium};
            cursor: default;
            opacity: 0.7;
        }
    }    
`;