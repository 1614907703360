import styled from 'styled-components'

import * as set from '../style/Variables/StyleSet';
import * as color from '../style/Variables/Colors';
import * as font from '../style/Variables/Fonts';

export const containerWrapper = styled.div`
    max-width: 2000px;
    margin: 0 auto;
    padding: 20px 30px;
`;
// MOBILE
export const MainNavMobile = styled.div`
@media (max-width: 992px) {
    .mobile_nav_btn {
        float: right;
        font-size: ${font.xlFontSize};
        border: none;
        line-height: 0;
        background: transparent;
        color: ${color.mGreyDark};
        height: 40px;
        width: 40px;
    }
    .mobile_nav__open {
        height: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        background-color: white;
        overflow-x: hidden;
        transition: .5s;
        padding-top: 40px;
        width: 250px;

        ${set.defaultShadow}

        .mobile_nav_close {
            font-size: 36px;
            float: right;
            color: ${color.black};
            position: absolute;
            right: 0;
            top: 0;
            font-weight: 100;
            border: none;
            background: transparent;
            line-height: 0;
            height: 40px;
            width: 40px;
        }
        .user_initials_btn {
            background: ${color.primaryColor};
            color: ${color.white};
            text-transform: uppercase;
            font-size: ${font.xsFontSize};
            padding: 0;
            height: 30px;
            width: 30px;
            display: inline-block;
            font-weight: 500;
            vertical-align: middle;
            padding-top: 6px;
            text-align: center;
            position: absolute;
            top: 10px;
            left: 10px;
            ${set.radius100}
        }
        .user_name {
            font-size: ${font.sFontSize};
            font-weight: 500;
            padding-left: 10px;
        }
        .logout_link_mobile {
            width: 100%;
            padding: 10px 20px;
            outline: 0;
            color: ${color.black};
            font-size: ${font.sFontSize};
            display: block;
            font-weight: 400;
            cursor: pointer;
            border-top: 1px solid rgba(0,0,0,.1);
            i {
                color: ${color.grey};
                padding-right: 5px;
                font-size: ${font.mFontSize};
            }
        }
    }
}
`;
export const MainNavMobileOpenLinks = styled.div`
    @media (max-width: 992px) {
        display: table;
        table-layout: fixed;
        width: 100%;
        margin: 0;
        text-decoration: none;
        position: relative;

        &.active {
            background-color: ${color.primaryColorLight};
        }
        &.border_link {
            border-top: 1px solid rgba(0,0,0,.1);
        }
        i {
            color: ${color.grey};
            padding-right: 5px;
            font-size: ${font.mFontSize};
        }
        .bgdColor {
            &.primary i { color: ${color.primaryColorDark}; }
            &.red i { color: ${color.mRedDark}; }
            &.orange i { color: ${color.mOrangeDark}; }
            &.yellow i { color: ${color.mYellowDark}; }
            &.green i { color: ${color.mGreenDark}; }
            &.mint i { color: ${color.mMintDark}; }
            &.aqua i { color: ${color.mAquaDark}; }
            &.blue i { color: ${color.mBlueDark}; }
            &.purple i { color: ${color.mPurpleDark}; }
            &.pink i { color: ${color.mPinkDark}; }
            &.ocher i { color: ${color.mOcherDark}; }
            &.grey i { color: ${color.mGreyDark}; }
        }
        a {
            width: 100%;
            padding: 10px 20px;
            outline: 0;
            color: ${color.black};
            font-size: ${font.sFontSize};
            display: block;
            font-weight: 400;
        }
        &.active {
            .bgdColor {
                background: ${color.mGreyLight};
                &.primary { background: ${color.primaryColor}; }
                &.red { background: ${color.mRed}; }
                &.orange { background: ${color.mOrange}; }
                &.yellow { background: ${color.mYellowDark}; }
                &.green { background: ${color.mGreen}; }
                &.mint { background: ${color.mMint}; }
                &.aqua { background: ${color.mAqua}; }
                &.blue { background: ${color.mBlue}; }
                &.purple { background: ${color.mPurple}; }
                &.pink { background: ${color.mPink}; }
                &.ocher { background: ${color.mOcher}; }
                &.grey { background: ${color.mGrey}; }
            }
            i, a {
                color: ${color.white} !important;
            }
        }
    }
`;
export const SubNavMobile = styled.div`
@media (max-width: 992px) {
    .sub_nav_mobile {
        height: 40px;
        padding: 0;
        background: ${color.white};
        text-align: left;

        ${set.defaultShadow}

        .sub_nav_wrapper {
            width: calc(100% - 40px);
            display: table;
            position: absolute;
            height: 40px;

            &.primary { background: ${color.primaryColor}; }
            &.red { background: ${color.mRed}; }
            &.orange { background: ${color.mOrange}; }
            &.yellow { background: ${color.mYellowDark}; }
            &.green { background: ${color.mGreen}; }
            &.mint { background: ${color.mMint}; }
            &.aqua { background: ${color.mAqua}; }
            &.blue { background: ${color.mBlue}; }
            &.purple { background: ${color.mPurple}; }
            &.pink { background: ${color.mPink}; }
            &.ocher { background: ${color.mOcher}; }
            &.grey { background: ${color.mGrey}; }
        }
        a {
            color: ${color.white};
            font-weight: 400;
            font-size: ${font.xlFontSize};
            text-transform: initial;
            display: block;
        }
    }
    .material-icons {
        line-height: 0;
        padding: 8px;
    }
}
`;
export const SubNavigationLinkMobile = styled.div`
    &.primary { background: ${color.primaryColor}; }
    &.red { background: ${color.mRed}; }
    &.orange { background: ${color.mOrange}; }
    &.yellow { background: ${color.mYellowDark}; }
    &.green { background: ${color.mGreen}; }
    &.mint { background: ${color.mMint}; }
    &.aqua { background: ${color.mAqua}; }
    &.blue { background: ${color.mBlue}; }
    &.purple { background: ${color.mPurple}; }
    &.pink { background: ${color.mPink}; }
    &.ocher { background: ${color.mOcherDark}; }
    &.grey { background: ${color.mGrey}; }

    @media (max-width: 992px) {
        text-align: center;
        min-width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        display: table-cell;
        vertical-align: middle;

        &.sub_nav_active {
            background-color: ${color.primaryColorMedium};

            &.primary { background: ${color.primaryColorLight}; }
            &.red { background: ${color.mRedLight}; }
            &.orange { background: ${color.mOrangeLight}; }
            &.yellow { background: ${color.mYellow}; }
            &.green { background: ${color.mGreenLight}; }
            &.mint { background: ${color.mMintLight}; }
            &.aqua { background: ${color.mAquaLight}; }
            &.blue { background: ${color.mBlueLight}; }
            &.purple { background: ${color.mPurpleLight}; }
            &.pink { background: ${color.mPinkLight}; }
            &.ocher { background: ${color.mOcher}; }
            &.grey { background: ${color.mGreyLight}; }
        }
    }
`;

// DESKTOP
export const LogoStyle = styled.img`
    width: 160px;
`;
export const UserBtnBox = styled.div`
    float: right;
    margin-left: 20px;
    margin-right: 20px;
    padding-top: 7px;
    display: -webkit-inline-box;
    position: relative;
`;
export const UserBtn = styled.button`
    border: none;
    background: transparent;
    padding: 0;
    
    .user_initials_btn {
        background: ${color.primaryColor};
        color: ${color.white};
        text-transform: uppercase;
        font-size: ${font.xsFontSize};
        padding: 0;
        height: 30px;
        width: 30px;
        display: inline-block;
        font-weight: 500;
        vertical-align: middle;
        padding-top: 6px;
        text-align: center;
        ${set.radius100}
    }
    .user_arrow_btn {
        font-size: ${font.xsFontSize};
        padding-left: 2px;
        vertical-align: middle;
    }
`;
export const UserBtnNav = styled.div`
    display: none;
    position: absolute;
    //transform: translate3d(-154px,15px,0px);
    min-width: 200px;
    max-width: 300px;
    text-align: left;
    background-color: ${color.white};
    border: 1px solid rgba(0,0,0,.15);
    right: -25px;
    top: 50px;
    ${set.radius6px}
    ${set.defaultShadow}

    &:focus { ${set.hardShadow} }
    &.dropdown_active {
        display: block;
        z-index: 5;
    }
    .user_nav_before:before {
        content: '';
        height: 12px;
        border-left: 16px solid transparent;
        border-right: 16px solid transparent;
        border-bottom: 11px solid #ccc;
        right: 14px;
        position: absolute;
        top: -12px;
    }
    .user_nav_before:after {
        content: '';
        height: 10px;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom: 10px solid #fff;
        right: 15px;
        position: absolute;
        top: -10px;
    }
    .user_nav_header {
        padding: 10px 10px 0 10px;
        white-space: nowrap;
        .user_initials {
            background: ${color.primaryColor};
            color: ${color.white};
            text-transform: uppercase;
            font-size: ${font.xsFontSize};
            line-height: 2.4;
            padding: 0;
            height: 30px;
            width: 30px;
            display: inline-block;
            font-weight: 500;
            vertical-align: middle;
            text-align: center;
            ${set.radius100}
        }
        .user_name {
            font-size: ${font.sFontSize};
            color: ${color.black};
            padding-left: 5px;
            ${set.noWrap}
            max-width: 250px;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .dropdown_link {
        white-space: nowrap;
        font-size: ${font.xsFontSize};
        display: block;
        border-top: ${color.greyLight} solid 1px;
        // padding: 10px;
        
        ${set.transitionAll}

        color: ${color.grey};
        cursor: pointer;
        &:hover {
            background-color: ${color.greyLight};
        }
        i {
            font-size: ${font.lFontSize};
            line-height: 9px;
        }
        a {
            color: ${color.grey};
            display: block;
            padding: 10px;
            &:hover {
                text-decoration: none;
            }
    
            i {
                font-size: ${font.lFontSize};
                line-height: 9px;
            }
        }
        .logout_link {
            color: ${color.grey};
            display: block;
            padding: 10px;
            &:hover {
                text-decoration: none;
            }
    
            i {
                font-size: ${font.lFontSize};
                line-height: 9px;
            }
        }
        svg {
            width: 15px;
            height: 15px;
            vertical-align: text-bottom;
            margin-right: 4px;
        }
    }
`;
export const MainNavDesktop = styled.div`
    float: right;
    margin-top: 3px;
`;
export const MainNavBox = styled.div`
    display: inline-block;
    padding: 1px 4px;
`;
export const MainNavLink = styled.button`
    border: none;
    background: transparent;

    span {
        &.primary { i { color: ${color.primaryColor}; } }
        &.red { i { color: ${color.mRed}; } }
        &.orange { i { color: ${color.mOrange}; } }
        &.yellow { i { color: ${color.mYellowDark}; } }
        &.green { i { color: ${color.mGreen}; } }
        &.mint { i { color: ${color.mMint}; } }
        &.aqua { i { color: ${color.mAqua}; } }
        &.blue { i { color: ${color.mBlue}; } }
        &.purple { i { color: ${color.mPurple}; } }
        &.pink { i { color: ${color.mPink}; } }
        &.ocher { i { color: ${color.mOcher}; } }
        &.grey { i { color: ${color.mGrey}; } }
    }
    
    & i {
        font-size: ${font.xxxxlFontSize};
        color: ${color.black};
        text-align: center;
        line-height: 34px;
        width: 42px;
        height: 42px;
        display: block;
        &:before {
            display: block;
            width: 42px;
            height: 42px;
            line-height: 42px;
            ${set.radius100}
        }
    }
    &.main_nav_active {
        i {
            color: ${color.white} !important;
        }
        i:before {
            background-color: ${color.secondaryColor};
            display: block;
            width: 42px;
            height: 42px;
            line-height: 42px;
            ${set.radius100}
            
        }
        span {
            &.primary { i:before { background: ${color.primaryColor}; } }
            &.red { i:before { background: ${color.mRed}; } }
            &.orange { i:before { background: ${color.mOrange}; } }
            &.yellow { i:before { background: ${color.mYellowDark}; } }
            &.green { i:before { background: ${color.mGreen}; } }
            &.mint { i:before { background: ${color.mMint}; } }
            &.aqua { i:before { background: ${color.mAqua}; } }
            &.blue { i:before { background: ${color.mBlue}; } }
            &.purple { i:before { background: ${color.mPurple}; } }
            &.pink { i:before { background: ${color.mPink}; } }
            &.ocher { i:before { background: ${color.mOcher}; } }
            &.grey { i:before { background: ${color.mGrey}; } }
        }
    }
    &:hover {
        i:before {
            background-color: ${color.secondaryColorLight};
            display: block;
            width: 42px;
            height: 42px;
            line-height: 42px;
            color: ${color.white};
            ${set.radius100}
        }
        span {
            &.primary { i:before { background: ${color.primaryColorLight}; } }
            &.red { i:before { background: ${color.mRedLight}; } }
            &.orange { i:before { background: ${color.mOrangeLight}; } }
            &.yellow { i:before { background: ${color.mYellow}; } }
            &.green { i:before { background: ${color.mGreenLight}; } }
            &.mint { i:before { background: ${color.mMintLight}; } }
            &.aqua { i:before { background: ${color.mAquaLight}; } }
            &.blue { i:before { background: ${color.mBlueLight}; } }
            &.purple { i:before { background: ${color.mPurpleLight}; } }
            &.pink { i:before { background: ${color.mPinkLight}; } }
            &.ocher { i:before { background: ${color.mOcherLight}; } }
            &.grey { i:before { background: ${color.mGreyLight}; } }
        }
    }
    &.main_nav_active:hover {
        i:before {
            background-color: ${color.secondaryColor};
        }
    }
`;
export const SubNavigation = styled.div`
    background: ${color.primaryColor};
    padding: 10px 0;
    text-align: left;
    height: 50px;
    .sub_nav_wrapper {
        max-width: 2000px;
        margin: 0 auto;
    }
    a {
        color: ${color.white};
        font-weight: 400;
        font-size: ${font.mFontSize};
        text-transform: initial;
    }
    &.red { background: ${color.mRed}; }
    &.orange { background: ${color.mOrange}; }
    &.yellow { background: ${color.mYellowDark}; }
    &.green { background: ${color.mGreen}; }
    &.mint { background: ${color.mMint}; }
    &.aqua { background: ${color.mAqua}; }
    &.blue { background: ${color.mBlue}; }
    &.purple { background: ${color.mPurple}; }
    &.pink { background: ${color.mPink}; }
    &.ocher { background: ${color.mOcher}; }
    &.grey { background: ${color.mGrey}; }
`;
export const SubNavigationLink = styled.div`
    display: inline-block;
    margin-left: 20px;
    text-align: center;
    min-width: 80px;
    &.sub_nav_active:after {
        content: " ";
        position: relative;
        display: block;
        top: 13px;
        left: calc(50% - 30px);
        width: 60px;
        height: 10px;
        border-top: solid 10px ${color.primaryColor};
        border-left: solid 30px transparent;
        border-right: solid 30px transparent;
    }
    &.sub_nav_active.red:after { border-top: solid 10px ${color.mRed}; }
    &.sub_nav_active.orange:after { border-top: solid 10px ${color.mOrange}; }
    &.sub_nav_active.yellow:after { border-top: solid 10px ${color.mYellowDark}; }
    &.sub_nav_active.green:after { border-top: solid 10px ${color.mGreen}; }
    &.sub_nav_active.mint:after { border-top: solid 10px ${color.mMint}; }
    &.sub_nav_active.aqua:after { border-top: solid 10px ${color.mAqua}; }
    &.sub_nav_active.blue:after { border-top: solid 10px ${color.mBlue}; }
    &.sub_nav_active.purple:after { border-top: solid 10px ${color.mPurple}; }
    &.sub_nav_active.pink:after { border-top: solid 10px ${color.mPink}; }
    &.sub_nav_active.ocher:after { border-top: solid 10px ${color.mOcher}; }
    &.sub_nav_active.grey:after { border-top: solid 10px ${color.mGrey}; }
`;

// Block Navigation

export const BlockNav = styled.div`
    margin: 10px 0;
    .block {
        background: ${color.greyLight};
        padding: 40px 10px;
        text-align: center;
        margin-bottom: 15px;
        position: relative;
        ${set.radius6px}
        ${set.defaultShadow}

        @media (max-width: 992px) {
            & {
                text-align: left;
            }
        }

        &.primary { background: ${color.primaryColor}; }
        &.red { background: ${color.mRed}; }
        &.orange { background: ${color.mOrange}; }
        &.yellow { background: ${color.mYellowDark}; }
        &.green { background: ${color.mGreen}; }
        &.mint { background: ${color.mMint}; }
        &.aqua { background: ${color.mAqua}; }
        &.blue { background: ${color.mBlue}; }
        &.purple { background: ${color.mPurple}; }
        &.pink { background: ${color.mPink}; }
        &.ocher { background: ${color.mOcher}; }
        &.grey { background: ${color.mGrey}; }

        .block_nav_icon {
            font-size: 32px;
            margin: 0 0 15px;
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            background: ${color.secondaryColor};
            color: ${color.white};
            border-radius: 50% 6px 50% 50%;
            max-width: 52px;
            max-height: 52px;
            padding: 10px;
            line-height: 0;
            
            ${set.transitionAll}

            &.primary { background: ${color.primaryColor}; }
            &.red { background: ${color.mRed}; }
            &.orange { background: ${color.mOrange}; }
            &.yellow { background: ${color.mYellowDark}; }
            &.green { background: ${color.mGreen}; }
            &.mint { background: ${color.mMint}; }
            &.aqua { background: ${color.mAqua}; }
            &.blue { background: ${color.mBlue}; }
            &.purple { background: ${color.mPurple}; }
            &.pink { background: ${color.mPink}; }
            &.ocher { background: ${color.mOcher}; }
            &.grey { background: ${color.mGrey}; }
        }
        .block_nav_title {
            display: block;
            font-size: ${font.titleFontSize};
            font-weight: 500;
            line-height: 22px;
            color: ${color.white};
        }
        @media (max-width: 992px) {
            .block_nav_icon {
                font-size: ${font.xxlFontSize};
            }

            .block_nav_title {
                position: absolute;
                bottom: 5px;
            }
        }
    }
    .block:hover {
        ${set.hardShadow}

        .block_nav_icon {
            background: ${color.secondaryColorLight};
            
            &.primary { background: ${color.primaryColorDark}; }
            &.red { background: ${color.mRedDark}; }
            &.orange { background: ${color.mOrangeDark}; }
            &.yellow { background: ${color.mYellow}; }
            &.green { background: ${color.mGreenDark}; }
            &.mint { background: ${color.mMintDark}; }
            &.aqua { background: ${color.mAquaDark}; }
            &.blue { background: ${color.mBlueDark}; }
            &.purple { background: ${color.mPurpleDark}; }
            &.pink { background: ${color.mPinkDark}; }
            &.ocher { background: ${color.mOcherDark}; }
            &.grey { background: ${color.mGreyDark}; }
        }
    }
    
`;
