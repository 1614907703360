const initialState = {
    hcSurveyUuidjptng: '',
    hcSurveyUuidp2ng: '',
    hcSurveyUuid_min: '',
    hcSurveyUuid_geo: '',
    hcSurveyUuid_pro: '',
    hcSurveyUuid_prv: ''
}

const surveyReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_UUID_JPT': 
            return { ...state, hcSurveyUuidjptng: action.payload }
        case 'SET_USER_UUID_P2': 
            return { ...state, hcSurveyUuidp2ng: action.payload }
        case 'SET_USER_UUID_MIN': 
            return { ...state, hcSurveyUuid_min: action.payload }
        case 'SET_USER_UUID_GEO': 
            return { ...state, hcSurveyUuid_geo: action.payload }
        case 'SET_USER_UUID_PRO': 
            return { ...state, hcSurveyUuid_pro: action.payload }
            case 'SET_USER_UUID_PRV': 
        return { ...state, hcSurveyUuid_prv: action.payload }
        default: {
            return state;
        }
    }
}

export default surveyReducer;