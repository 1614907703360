const initialState = {
    isOnline: false,
    isMobile: false,
    loading: false,
    history: []
}

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ONLINE': 
            return { ...state, isOnline: true }
        case 'OFFLINE':
            return { ...state, isOnline: false }
        case 'IS_MOBILE': 
            return { ...state, isMobile: true }
        case 'NOT_MOBILE':
            return { ...state, isMobile: false }
        case 'START_LOADING': 
            return { ...state, loading: true }
        case 'STOP_LOADING':
            return { ...state, loading: false }
        case 'NAVIGATION_HISTORY':
            return {
                ...state,
                history: state.history.length < 9 ? [...state.history, action.payload] : [...state.history.slice(1), action.payload]
            }
        case 'LOGOUT':
        return { ...initialState }
        default: {
            return state;
        }
    }
}

export default appReducer;