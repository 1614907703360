import React, { Suspense } from 'react';
import { Router } from '@reach/router';
import ScreenLoading from '../components/loader/ScreenLoading';

const ComponentRenderRoot = (props) => {    

    return(
        <div>
            <Suspense fallback={<ScreenLoading />}>
                <Router tabIndex={'false'}>
                    { props.filteredRoutes.map( route => route.component )}
                </Router>
            </Suspense>
        </div>
    )
}

export default ComponentRenderRoot;