import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

const AlertModal = ({ title, text, icon, onConfirm, confirmBtnText, onCancel, cancelBtnText, showCancel }) => {
    return(
        <SweetAlert
            custom
            title={title}
            customIcon={icon}
            onConfirm={onConfirm}
            btnSize="sm"
            confirmBtnText={confirmBtnText}
            showCancel={showCancel}
            onCancel={onCancel}
            cancelBtnText={cancelBtnText}
        >
            {text}
        </SweetAlert>
    )
}

export default AlertModal;