import axios from "axios";

let baseURL = "https://anywhere-server-urm.darthzejdr.eu"
// let baseURL = "http://localhost:8000"

// hc survey system
if(window.location.hostname === 'mjera11-mgipu.eu') baseURL = "https://server.mjera11-mgipu.eu/"
if(window.location.hostname === 'openapi.darthzejdr.eu') baseURL = "https://openapi-server.darthzejdr.eu/"

export default axios.create({
  baseURL: baseURL,
  responseType: "json"
});