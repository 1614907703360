import React from 'react';
// Bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

import '../node_modules/react-grid-layout/css/styles.css';
import '../node_modules/react-resizable/css/styles.css';

import './App.css';
import GlobalStyle from './style/GlobalStyle';
import './style/Icons.css';

// components
import AppRoot from './containers/AppRoot';

// store & persist
import { Provider } from 'react-redux';
import store, { persistor } from './store/store';
import { PersistGate } from "redux-persist/integration/react";

// route
import { LocationProvider } from "@reach/router"
import { history } from './helpers/routerHistory'



function App() {
  return (
		<Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <LocationProvider history={history}>
          <div className="App">
            <AppRoot />
            <GlobalStyle />
          </div>
        </LocationProvider>
      </PersistGate>
		</Provider>
  );
}

export default App;
