import { io } from "socket.io-client";
// import { toast } from 'react-toastify';


// socket init
const socket = io("https://anywhere-server-urm.darthzejdr.eu")

socket.on('connect', () => {console.log("socket connected")})
socket.on('disconnect', () => {console.log("socket disconnected")})
socket.on('connect_error', (e) => {console.log("socket connect error: ", e)})
socket.on('connect_timeout', (e) => {console.log("socket connect timeout: ", e)})


// socket.on('chat', (data) => {
//     console.log('CHAT data', data)
//     toast.success((data))
// })


export default socket;