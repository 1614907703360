import React from 'react';
import { useSelector } from 'react-redux';
import routes from '../router/routes'
// public access
// import queryString from 'query-string';

import Login from './Auth/Login';
// import * as palette from '../style/Variables';

// socket init
import socket from '../utils/socket';
// requests listener
import ResponseInterceptor from '../utils/ResponseInterceptor';
import NavigationRoot from './NavigationRoot';
import ComponentRenderRoot from './ComponentRenderRoot'
import ScreenLoading from '../components/loader/ScreenLoading';
import * as Styled from "./style";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Public components
// import SurveyJPTNG from './Public/SurveyHCJ_JPT_NG';
// import SurveyP2NG from './Public/SurveyHCJ_P2_NG';
// import SurveyWithTime from './Public/SurveyWithTime';
// import SurveyWithTimePRV from './Public/SurveyWithTimePRV';
// import SurveyGEO from './Public/SurveyHCJ_GEO';
// import SurveyPRO from './Public/SurveyHCJ_PRO';


const AppRoot = () => {
    const isAuthenticated = useSelector(state => state.user.isAuthenticated)
    const user = useSelector(state => state.user)
    const loading = useSelector(state => state.app.loading)
	// custom hook for filteredRoutes
    const filteredRoutes = useFilterRoutesByPermission()
	// custom hook for navigation style
    const navigationClass = useUserNavigationStyle()


	// public access condition
    let publicAccess = {state: false, survey: ''}
    // const { survey, uuid, department } = queryString.parse(window.location.search)

    // public redirect
    // if(survey === 'jpt-ng') publicAccess = {state: true, survey: 'jpt-ng'}
    // if(survey === 'p2-ng') publicAccess = {state: true, survey: 'p2-ng'}
    // if(survey === 'min') publicAccess = {state: true, survey: 'min'}
    // if(survey === 'geo') publicAccess = {state: true, survey: 'geo'}
    // if(survey === 'pro') publicAccess = {state: true, survey: 'pro'}
    // if(survey === 'prv') publicAccess = {state: true, survey: 'prv'}

    // socket check
    if(isAuthenticated) {
        !socket.connected && socket.connect()
        socket.emit('token', user.token.split(' ')[1])
    } else {
        socket.disconnect()
    }


    const appContent = (
        <div>
			<ResponseInterceptor />
            <NavigationRoot filteredRoutes={filteredRoutes} />
            <Styled.containerWrapper>
                <div className={navigationClass}>
                    <ComponentRenderRoot filteredRoutes={filteredRoutes} />
                </div>
            </Styled.containerWrapper>
        </div>
    )

    return(
        <div className="Application">
            {/* toast alert global settings */}
            <ToastContainer 
                autoClose={2500}
                closeButton={false}
                position={'top-right'}
                hideProgressBar={false}
                closeOnClick={true}
                pauseOnHover={true}
                draggable={true}
            />
            {loading && <ScreenLoading />}
            {/* master */}
            {!isAuthenticated && !publicAccess.state && <Login />}

            {/* hc survey - homepage */}
            {/* {!isAuthenticated && !publicAccess.state && <div style={{height: '100vh'}}><span style={{position: 'fixed', top: '45%', left: '50%'}}>{palette.home}</span></div>} */}
            {isAuthenticated && appContent}

            {/* Public */}
            {/* {!isAuthenticated && publicAccess.state && publicAccess.survey === 'jpt-ng' && <SurveyJPTNG uuid={uuid} />}
            {!isAuthenticated && publicAccess.state && publicAccess.survey === 'p2-ng' && <SurveyP2NG department={department} />} */}
            {/* {!isAuthenticated && publicAccess.state && publicAccess.survey === 'min' && <SurveyWithTime uuid={uuid} department={department} />}
            {!isAuthenticated && publicAccess.state && publicAccess.survey === 'prv' && <SurveyWithTimePRV uuid={uuid} department={department} />}
            {!isAuthenticated && publicAccess.state && publicAccess.survey === 'geo' && <SurveyGEO uuid={uuid} department={department} />}
            {!isAuthenticated && publicAccess.state && publicAccess.survey === 'pro' && <SurveyPRO uuid={uuid} department={department} />} */}
        </div>
    )
}

// filter routes by permission hook
function useFilterRoutesByPermission() {
	const permissions = useSelector(state => state.user.permissions)
	return routes.filter(route => permissions.hasOwnProperty(route.permission) && permissions[route.permission])
}

function useUserNavigationStyle() {
	const user = useSelector(state => state.user)
    if(user.hasOwnProperty('Organization')) {
        if(user.Organization.hasOwnProperty('navigation')) {
            if(user.Organization.navigation === 'white') {
                return 'container_left'
            }
        }
    }
    return 'container'
}

export default AppRoot;