import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { registerUser } from '../../store/appStore/actions/userActions';
import Input from '../../components/form/Input';
import TextIconButton from '../../components/button/TextIconButton';
import * as palette from '../../style/Variables';
import { toast } from 'react-toastify';
import AlertSimple from '../../components/alert/AlertSimple';
import t from '../../helpers/Translation';


const Register = (props) => {
    const strings = useSelector(state => state.user.langStrings)
    const userStoreLang = {
        language: useSelector(state => state.user.language),
        languageVersion: useSelector(state => state.user.languageVersion)
    }
    const organization = props.organization
    const dispatch = useDispatch()

    const [ name, setName ] = useState('')
    const [ email, setEmail ] = useState('')
    const [ password, setPassword ] = useState('')
    const [ emailError, setEmailError ] = useState(false)


    const handleChange = (e) => {
        if(e.target.name === 'name') setName(e.target.value)
        if(e.target.name === 'email') setEmail(e.target.value)
        if(e.target.name === 'password') setPassword(e.target.value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        if(email === '' || name === '' || password === '') {
            toast.error((<AlertSimple icon={palette.x} text={t('Fill required data', strings)} />))
        } else {
            registerUser({email, password}, dispatch, actionMsg, userStoreLang, organization, name)
        }
    }

    const actionMsg = (msg, type) => {
        if(msg === 'Validation errors') {
            setEmailError(true)
            toast.error((<AlertSimple icon={palette.x} text={t('Email validation error', strings)} />))
            return
        }
        if(msg === 'email must be unique') setEmailError(true)
        if(type === 'success') return toast.success((<AlertSimple icon={palette.x} text={t(msg, strings)} />))
        toast.error((<AlertSimple icon={palette.x} text={t(msg, strings)} />))
    }



    return(
        <div>
            <h1>{t('Register', strings)}</h1>
            <form onSubmit={onSubmit}>
                <Input
                    icon={palette.laUser}
                    type={'text'}
                    name={'name'}
                    value={name}
                    placeholder={t('Name', strings)}
                    onChange={handleChange}
                    autoComplete={'current-name'}
                    errorBottomLine={name !== '' ? false : true}
                />
                <Input
                    icon={palette.envelope}
                    type={'text'}
                    name={'email'}
                    value={email}
                    placeholder={t('Email', strings)}
                    onChange={handleChange}
                    autoComplete={'current-email'}
                    errorBottomLine={email !== '' && !emailError ? false : true}
                />
                <Input
                    icon={palette.laLock}
                    type={'password'}
                    name={'password'}
                    value={password}
                    placeholder={t('Password', strings)}
                    onChange={handleChange}
                    autoComplete={'current-password'}
                    errorBottomLine={password !== '' ? false : true}
                />
                <TextIconButton type="submit" actionClick={onSubmit} icon={palette.laCheck} btnText={t('Register', strings)} bgd={'secondary'} tooltip={t('Register', strings)}></TextIconButton>
            </form>
        </div>
    )
}

export default Register;