import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import t from '../../helpers/Translation';
import { logoutUser } from '../../store/appStore/actions/userActions'
import ScreenLoading from '../../components/loader/ScreenLoading'
import LogoutModal from '../../components/modal/LogoutModal';
import socket from '../../utils/socket';


const Logout = (callback) => {
    const strings = useSelector(state => state.user.langStrings)
    const dispatch = useDispatch()
    const [ loading, setLoading ] = useState(false)

    const handleLogoutUser = () => {
        setLoading(true)
        socket.disconnect()
        setTimeout(() => { 
            setLoading(false)
            logoutUser(dispatch)
        }, 500)
    }

    return(
        <div>
            {loading && <ScreenLoading />}
            {!loading && <LogoutModal
                close={() => callback.callback.handleLogoutClose()}
                title={t('Logout', strings)} 
                question={t('Are you sure you want to logout?', strings)} 
                action={() => handleLogoutUser()} 
            />   }         
        </div>

    )
}

export default Logout;