import styled from 'styled-components';
import background from '../../img/bgd/login_background.jpg';

import * as color from '../../style/Variables/Colors';
import * as font from '../../style/Variables/Fonts';

// style of component
export const containerWrapper = styled.div`
    width: 100%;
    height: 100vh;
    .login_row {
        display: table;
        width: 100%;
        height: 100%;
        .login_col_xl_6 {
            width: 50%;
            display: table-cell;
            padding: 20px;
            text-align: center;
            vertical-align: middle;
            h1 {
                text-align: center;
                font-size: ${font.xxlFontSize};
                color: ${color.black}; 
                margin-bottom: 30px;
            }
            form {
                max-width: 400px;
                margin: 0 auto;
                .remember_box {
                    margin-top: 20px;
                    .checkbox_label {
                        font-size: ${font.defaultFontSize};
                        color: ${color.greyMedium}; 
                    }
                    .text_link {
                        font-size: ${font.defaultFontSize};
                        color: ${color.secondaryColor}; 
                        cursor: pointer;
                    }
                }
                button {
                    margin: 30px 0 70px 0;
                }
            }
        }
        @media (max-width: 992px) {
            .login_col_xl_6 {
                width: 100%;
                display: block;
                margin-top: 90px;
            }
        }
    }
    .login_right_side {
        justify-content: center;
        background-image: url(${background});
        background-attachment: fixed;
        background-size: cover;
    }
    @media (max-width: 992px) {
        .login_right_side {
            position: absolute;
            top: 0;
            margin-top: 0 !important;
        }
    }
`;
export const LogoSignStyle = styled.img`
    width: 120px;
    margin: 0 auto;
    display: block;
    margin-bottom: 50px;
    @media (max-width: 992px) {
        width: 80px;
        margin-bottom: 40px;
    }
`;
export const LogoStyle = styled.img`
    max-width: 400px;
    margin: auto;
    display: block;
    width: 100%;
    @media (max-width: 992px) {
        display: none;
    }
`;
export const LogoMobileStyle = styled.img`
    max-width: 190px;
    margin: auto;
    display: none;
    width: 100%;
    @media (max-width: 992px) {
        display: block;
    }
`;

