import styled from 'styled-components';

import * as font from '../../style/Variables/Fonts';

export const AuthAlert = styled.div`
    .sweet-alert  {
        a {
            padding: 0 20px;
            height: 30px;
            svg {
                font-size: ${font.xxxxlFontSize}; !important;
            }
        }
    }
`
