import React from 'react';
import * as Styled from "./style";

import AlertModal from '../../components/alert/AlertModal';


const ModalAlert = ({ title, text, onConfirm, confirmBtnText, icon, showCancel, onCancel, cancelBtnText }) => {
    return(
        <Styled.AuthAlert>
            <AlertModal
                icon={ icon }
                title={title}
                text={text}
                onConfirm={onConfirm}
                confirmBtnText={confirmBtnText}

                showCancel={showCancel ? true : false}
                onCancel={onCancel}
                cancelBtnText={cancelBtnText}
            />
        </Styled.AuthAlert>
    )
}

export default ModalAlert;