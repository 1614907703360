import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import t from '../helpers/Translation';

import { Link } from '@reach/router';
import logo from '../img/anywhere.png';
import * as Styled from "./styleLeft";
import * as palette from '../style/Variables';
import _ from 'lodash'
import Logout from '../containers/Auth/Logout';

import NotificationDropdownLeft from '../components/button/NotificationDropdownLeft';
import Tooltip from '../components/base/Tooltip';

const MainNavigationLeft = ({ linkColor, navLinks, locationPath, isMobile }) => {
    const handleClick = () => {
        alert('you clicked')
    }
    const notifications =[
        {'notiBgd': 'primary', 'linkAction': handleClick, 'iconColor': 'primary', 'icon': palette.laStar, 'title':'Primary', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'Now' },
        {'notiBgd': 'secondary', 'linkAction': handleClick, 'iconColor': 'secondary', 'icon': palette.laRocket, 'title':'Secondary', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'12min ago' },
        {'notiBgd': 'success', 'linkAction': handleClick, 'iconColor': 'success', 'icon': palette.laCheck, 'title':'Success', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'1h ago' },
        {'notiBgd': 'danger', 'linkAction': handleClick, 'iconColor': 'danger', 'icon': palette.times, 'title':'Danger', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'1 day ago' },
        {'notiBgd': 'warning', 'linkAction': handleClick, 'iconColor': 'warning', 'icon': palette.exclTriangle, 'title':'Warning', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'1 week ago' },
        {'notiBgd': 'white', 'linkAction': handleClick, 'iconColor': 'secondary', 'icon': palette.history, 'title':'White', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'2 year ago' },
        {'notiBgd': 'white', 'linkAction': handleClick, 'iconColor': 'secondary', 'icon': palette.history, 'title':'White', 'desc':'Lorem ipsum dolor sit amet, consectetur adipiscing elit.', 'time':'2 year ago' }
    ]
    const strings = useSelector(state => state.user.langStrings)
    const [ currentLocation, setCurrentLocation ] = useState(window.location.pathname)
    const [ routeActive, setRouteActive ] = useState('')
    const [ currenRoutes, setCurrenRoutes ] = useState([])
    const [ moduleColor, setModuleColor ] = useState('')

    const [ mobileMenuRight, setMobileMenuRight ] = useState(-250)
    const [ displayProfileDropdown, setDisplayProfileDropdown ] = useState('none')
    const [ showLogout, setShowLogout ] = useState(false)

    const user = useSelector(state => state.user)
    let headerNavigation = !isMobile ? navLinks.filter(link => link.parentId === null && link.showRoute) : navLinks.filter(link => link.parentId === null && link.showMobileRoute)
    const helpRoute = navLinks.filter(link => link.text === 'Help')
    const notificationRoute = navLinks.filter(link => link.text === 'Notifications')
    const settingsRoute = navLinks.filter(link => link.text === 'Settings')

    // push Help & Settings in own array
    const secondNavParams = []
    helpRoute.length > 0 && secondNavParams.push(helpRoute[0])
    notificationRoute.length > 0 && secondNavParams.push(notificationRoute[0])
    settingsRoute.length > 0 && secondNavParams.push(settingsRoute[0])

    // sort header navigation by sortingOrder
    headerNavigation = _.sortBy(headerNavigation, ['headerNavigation', 'sortOrder'])

   
    // listener for location changes
    useEffect(() => {
        setCurrentLocation(locationPath)
        setRouteActive(locationPath)
    }, [locationPath])


    // listen for click event anywhere on site
	useEffect(() => {
		const handleClick = () => {if(displayProfileDropdown === 'block') setDisplayProfileDropdown('none')}
		window.addEventListener("click", handleClick)
		return () => {window.removeEventListener('click', handleClick)};
    }, [displayProfileDropdown])


    // listen for click event anywhere on site - mobile
	useEffect(() => {
		const handleClick = () => {if(mobileMenuRight === 0) setMobileMenuRight(-250)}
		window.addEventListener("click", handleClick)
		return () => {window.removeEventListener('click', handleClick)};
    }, [mobileMenuRight])


    // dynamicly filter submodules of currentLocation
    useEffect(() => {
        // currentLocation is always single value in array
        const component = navLinks.filter(l => l.path === currentLocation)
        // console.log('oooo', component)
        if(component.length === 0) return
        const subModuleFilter = navLinks.filter(link => link.parentId === component[0].id && link.showRoute)
        const subModuleCondition = navLinks.filter(link => link.parentId === component[0].id ).length > 0
        const rootModuleFilter = navLinks.filter(link => link.parentId === null && link.showRoute)
        const subModuleParentCondition = navLinks.filter(link => link.parentId === component[0].parentId && link.showRoute)
        if(component.length > 0 && component[0].parentId === null) {
            subModuleCondition ? setCurrenRoutes(subModuleFilter) : setCurrenRoutes(rootModuleFilter)
        } else {
            subModuleCondition ? setCurrenRoutes(subModuleFilter) : setCurrenRoutes(subModuleParentCondition)
        }
        // if route has singleComponent property true - hide subroutes in navigation
        if(component.length > 0 && component[0].hasOwnProperty('singleComponent')) setCurrenRoutes([])
        // find module color
        let moduleColor = navLinks.filter(n => n.parentId === null && currentLocation.includes(n.path) && n.path !== '/')
        moduleColor.length > 0 && moduleColor[0].hasOwnProperty('color') ? setModuleColor(moduleColor[0].color) : setModuleColor('')

    }, [currentLocation, navLinks])

    const handleRoutesMainNavigation = (link) => {
        if(currenRoutes.filter(l => l.parentId === link.id).length > 0) {
            setCurrenRoutes(currenRoutes.filter(l => l.parentId === link.id && link.showRoute ))
        }
    }

    const checkHeaderIconHover = (path) => {
        if(path === '/') return false
        if(routeActive.includes(path)) return true
        return false
    }

    const handleMobileNavClick = () => {
        mobileMenuRight === -250 ? setMobileMenuRight(0) : setMobileMenuRight(-250)
    }

    const handleLogoutClose = () => {
        setShowLogout(false)
    }

    return (
        <Styled.SmallNavigation>
            { !isMobile && currenRoutes.length > 0 &&
                <Styled.containerWrapper>
                    <Styled.TopNav>
                        {/* Header icon Navigation */}
                        <div className="top_nav_box nav_scroll_h">
                        { currenRoutes.map((link, index) => 
                                // hide main module names in nav
                                link.parentId !== null && <Link
                                    key={index}
                                    to={link.path}
                                    style={{ color: linkColor }}
                                    onClick={() => {handleRoutesMainNavigation(link)}}
                                >
                                <button key={ index } onClick={() => {handleRoutesMainNavigation(link)}}>
                                    {link && link.icon} <span className="top_btn_text">{t(link.text, strings)}</span>
                                </button>
                                </Link>
                        )}
                        </div>
                    </Styled.TopNav>
                    <div style={{clear: 'both'}}></div>
                </Styled.containerWrapper>
            }
            {/* Side Navigation MOBILE */}
            { isMobile &&
            <Styled.MainNavMobile>
                <button type="button" className="mobile_nav_btn"
                    onClick={handleMobileNavClick}>{palette.menu}
                </button>
                <div className="mobile_nav__open"
                    style={{ right: mobileMenuRight }}>
                    <button className="mobile_nav_close" type="button"
                        onClick={handleMobileNavClick}>&times;
                    </button>

                    {/* user profile dropdown MOBILE */}
                    <span className="user_initials_btn">{user.email.slice(0, 2)}</span>
                    <span className="user_name">{user.email}</span>
                    <div style={{clear: 'both'}}></div>
                    <hr></hr>
                    { headerNavigation.map((link, index) => 
                        <Styled.MainNavMobileOpenLinks
                            key={ index }
                            className={checkHeaderIconHover(link.path) ? 'active' : '' || link.text === 'Help' ? 'border_link' : ''}
                        >
                            <div className={"bgdColor " + (link.color)}>
                                <Link
                                    to={link.path}
                                    onClick={() => {
                                        handleRoutesMainNavigation(link)
                                        handleMobileNavClick()
                                    }}
                                >
                                    <i>{link && link.icon}</i>&nbsp;{t(link.text, strings)}
                                </Link>
                            </div>
                        </Styled.MainNavMobileOpenLinks>
                    )}
                    {/* MOBILE Logout link */}
                    <div className="logout_link_mobile" onClick={() => setShowLogout(true)}><i>{palette.logout}</i>&nbsp;{t('Logout', strings)}</div>
                </div>
            </Styled.MainNavMobile>}

            {/* main navigation */}
            { !isMobile && 
                <nav
                className="responsive-toolbar">
                <Styled.SideNav>
                    <div className="nav_box nav_scroll_v">
                        {/* logo link */}
                        <div className="top_logo_box">
                            <Link to='/' path='/' onClick={ () => { handleRoutesMainNavigation(navLinks[0]) } }>
                                <Styled.LogoStyle src={ logo } />
                            </Link>
                        </div>
                        { headerNavigation.map((link, index) => 
                            <span key={ index }>
                            {link &&
                                <Styled.SubNavigationLink
                                    key={ index }
                                    className={link.path === routeActive ? 'sub_nav_active' : ''}
                                >
                                    <Link
                                        to={link.path}
                                        onClick={() => {handleRoutesMainNavigation(link)}}
                                    >
                                        { !isMobile &&
                                            <span><span data-tip data-for={link.text}>
                                                <button className={"main_nav_btn " + (link.color)}>{link.icon}</button>
                                            </span>
                                            <Tooltip
                                                id={link.text}
                                                place={'right'}
                                                bgColor={link.color}
                                                effect={'solid'}
                                                tooltipText={t(link.text, strings)}
                                            /></span>
                                        }
                                        { isMobile && (link.icon) }
                                    </Link>
                                </Styled.SubNavigationLink>
                                }
                            </span>
                        )}
                        <Styled.UserOptions>
                            <Styled.UserBox>
                                <NotificationDropdownLeft
                                    align={'left'}
                                    icon={palette.laBell}
                                    bgd={'white'}
                                    dropdownData={notifications}
                                    tooltip={'Notifications'}
                                />
                                <Styled.UserBtn
                                    type="button"
                                    onClick={() => displayProfileDropdown === 'none' ? setDisplayProfileDropdown('block') : setDisplayProfileDropdown('none')} 
                                    >
                                    <span className="user_initials_btn">{user.email && user.email.slice(0, 2)}</span>
                                    {/* <span className="user_arrow_btn">{palette.angleDown}</span> */}
                                </Styled.UserBtn>
                                <Styled.UserBtnNav className={displayProfileDropdown !== 'none' ? 'dropdown_active' : ''} >
                                    <div className="user_nav_before"></div>
                                    <h6 className="user_nav_header">
                                        <span className="user_initials">{user.email && user.email.slice(0, 2) }</span>
                                        <span className="user_name">{user.email && user.email}</span>
                                    </h6>
                                    { secondNavParams.map((link, index) => 
                                        <span key={ index } className="dropdown_link">
                                            <Link to={link && link.path}>
                                                <div>{link && link.icon} {link && t(link.text, strings)}</div>
                                            </Link>
                                        </span>
                                    )}
                                    <span className="dropdown_link" onClick={() => setShowLogout(true)}>
                                        <div className="logout_link">{palette.logout} {t('Logout', strings)}</div>
                                    </span>
                                </Styled.UserBtnNav>
                            </Styled.UserBox>
                        </Styled.UserOptions>
                    </div>
                </Styled.SideNav>
            </nav> }
            { isMobile &&
            <Styled.SubNavMobile>
                <nav className="responsive-toolbar">
                    <div className="sub_nav_mobile">
                        <div className={'sub_nav_wrapper ' + moduleColor}>
                            { currenRoutes.map((link, index) => 
                                // hide main module names in nav
                                link.parentId !== null &&
                                <Styled.SubNavigationLinkMobile
                                    key={ index }
                                    className={(link.color) + ' ' + (link.path === routeActive ? 'sub_nav_active' : '')}
                                >
                                    <Link
                                        to={link.path}
                                        onClick={() => {handleRoutesMainNavigation(link)}}
                                    >
                                        { !isMobile && t(link.text, strings) }
                                        { isMobile && (link.icon) }
                                    </Link>
                                </Styled.SubNavigationLinkMobile>
                            )}
                        </div>
                    </div>
                </nav>
            </Styled.SubNavMobile> }

            {/* Logout */}
            {showLogout && <Logout callback={{handleLogoutClose}} />}
        </Styled.SmallNavigation>
    )
}

export default MainNavigationLeft;