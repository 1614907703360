import React from 'react';

const AlertSuccess = ({ icon, text }) => {
    return(
        <div className="alert_box">
            <span className="alert_icon">{icon}</span>
            <span className="alert_text">{text}</span>
        </div>
    )
}

export default AlertSuccess;