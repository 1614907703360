import styled from 'styled-components';

// style of component
export const containerWrapper = styled.div`
    margin: 10px 15px 15px;
    background-color: #fff;
    
    .container {
        max-width: 2000px;
        min-height: 80vh;
        margin: 20px auto;
        padding: 20px 15px; 
        -webkit-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
        -moz-box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);
        box-shadow: 0 1px 15px 1px rgba(113,106,202,.08);   
    }
    .container_left {
        /*max-width: 2000px;*/
        min-height: 80vh;
        margin: 0 auto;
        padding: 0 15px 0 65px; 
    }
    @media (max-width: 992px) {
        .container {
            margin: 0;
            padding: 20px 15px 20px 15px; 
            -webkit-box-shadow: none;
            -moz-box-shadow: none;
            box-shadow: none;
        }
        .container_left {
            padding: 10px 0; 
        }
    }
    @media (max-width: 400px) {
        /*padding: 0 15px;*/
    }
`;