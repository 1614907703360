import axios from '../../../utils/API';

// ADMIN

// APPLICATION API calls
// ------------------------------------------------------------------------------------------------
export async function getApplication(dataDB) {
    let getData = {applicationId: null}
    if(typeof dataDB !== 'undefined' && dataDB !== null) getData.applicationId = dataDB
    let response = await axios.get('/api/v1/application', { params: getData })
    return await response.data.data
}
export async function createApplication(dataDB) {
    let createData = { EAV: dataDB }
    let response = await axios.post('/api/v1/application', createData)
    return await response.data.data
}
export async function updateApplicationEAV(dataDB, modalItemId) {
    for(let one of dataDB) {
        one.applicationId = parseInt(modalItemId)
        if(one.id) one.id = parseInt(one.id)
    }
    let response = await axios.post('/api/v1/application/eav', dataDB)
    return await response.data.data
}
export async function deleteApplicationField(dataDB) {
    let response = await axios.delete('/api/v1/application/eav', { params: dataDB })
    return await response.data.data
}
export async function deleteApplication(dataDB) {
    let deleteData = {applicationId: dataDB}
    let response = await axios.delete('/api/v1/application', { params: deleteData })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------


// ROLE API calls
// ------------------------------------------------------------------------------------------------
export async function getRole(dataDB) {
    let getData = {applicationId: null}
    if(typeof dataDB !== 'undefined' && dataDB !== null) getData.applicationId = dataDB
    let response = await axios.get('/api/v1/application/role', { params: getData })
    return await response.data.data
}
export async function createRole(dataDB, applicationId) {
    let createData = { applicationId: parseInt(applicationId), EAV: dataDB }
    let response = await axios.post('/api/v1/application/role', createData)
    return await response.data.data
}
export async function updateRoleEAV(dataDB, modalItemId, applicationId) {
    for(let one of dataDB) {
        one.applicationRoleId = parseInt(modalItemId)
        if(one.id) one.id = parseInt(one.id)
        one.applicationId = parseInt(applicationId)
    }
    let response = await axios.post('/api/v1/application/role/eav', dataDB)
    return await response.data.data
}
export async function deleteRoleField(dataDB) {
    let response = await axios.delete('/api/v1/application/role/eav', { params: dataDB })
    return await response.data.data
}
export async function deleteRole(dataDB) {
    let response = await axios.delete('/api/v1/application/role', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------


// PERMISSION API calls
// ------------------------------------------------------------------------------------------------
export async function getPermission(dataDB) {
    let getData = {applicationId: null}
    if(typeof dataDB !== 'undefined' && dataDB !== null) getData.applicationId = dataDB
    let response = await axios.get('/api/v1/application/permission', { params: getData })
    return await response.data.data
}
export async function createPermission(dataDB, applicationId) {
    let createData = [{ applicationId: parseInt(applicationId), key: dataDB[0].value }]
    let response = await axios.post('/api/v1/application/permission', createData)
    return await response.data.data
}
export async function updatePermission(dataDB, modalItemId, applicationId) {
    let dataForDB = [{id: parseInt(modalItemId), applicationId: parseInt(applicationId), key: dataDB[0].value}]
    let response = await axios.post('/api/v1/application/permission', dataForDB)
    return await response.data.data
}
export async function deletePermission(dataDB) {
    let response = await axios.delete('/api/v1/application/permission', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------


// APPLICATION ROLE - PERMISSION API calls
// ------------------------------------------------------------------------------------------------
export async function getRolePermission(dataDB) {
    let response = await axios.get('/api/v1/application/role/permission', { params: dataDB })
    return await response.data.data
}
export async function updateRolePermission(dataDB) {
    let response = await axios.post('/api/v1/application/role/permission', dataDB)
    return await response.data.data
}
export async function deleteRolePermission(dataDB) {
    let response = await axios.delete('/api/v1/application/role/permission', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// APPLICATION PERMISSION - PERMISSION BACKEND API calls
// ------------------------------------------------------------------------------------------------
export async function getApplicationRoute(dataDB) {
    let response = await axios.get('/api/v1/application/route', { params: dataDB })
    return await response.data.data
}
export async function getApplicationPermisionRoute(dataDB) {
    let response = await axios.get('/api/v1/application/permission/route', { params: dataDB })
    return await response.data.data
}
export async function updateApplicationPermisionRoute(dataDB) {
    let response = await axios.post('/api/v1/application/permission/route', dataDB)
    return await response.data.data
}
export async function deleteApplicationPermisionRoute(dataDB) {
    let response = await axios.delete('/api/v1/application/permission/route', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------


// ORGANIZATION API calls
// ------------------------------------------------------------------------------------------------
// GET GOOD - send id(int) /if send id return one element /if null return all /if send "1,2" return 2 results
export async function getOrganization(dataDB) {
    let getData = {organizationId: null}
    if(typeof dataDB !== 'undefined' && dataDB !== null) getData.organizationId = dataDB
    let response = await axios.get('/api/v1/organization', { params: getData })
    return await response.data.data
}
export async function createOrganization(dataDB) {
    let response = await axios.post('/api/v1/organization', dataDB)
    return await response.data.data
}
export async function updateOrganizationEAV(dataDB, modalItemId) {
    // UPDATE id's -> INTEGER
    for(let one of dataDB) {
        one.organizationId = parseInt(modalItemId)
        if(one.id) one.id = parseInt(one.id)
    }
    let response = await axios.post('/api/v1/organization/eav', dataDB)
    return await response.data.data
}
export async function deleteOrganization(dataDB) {
    let deleteData = {organizationId: dataDB}
    let response = await axios.delete('/api/v1/organization', { params: deleteData })
    return await response.data.data
}
export async function deleteOrganizationField(dataDB) {
    let response = await axios.delete('/api/v1/organization/eav', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------


// ORGANIZATION APPLICATION API calls
// ------------------------------------------------------------------------------------------------
export async function getOrganizationApplication(dataDB) {
    let getData = {}
    if(typeof dataDB !== 'undefined' && dataDB !== null) getData.organizationId = parseInt(dataDB)
    let response = await axios.get('/api/v1/organization/application', { params: getData })
    return await response.data.data
}
export async function putOrganizationApplication(dataDB) {
    let response = await axios.post('/api/v1/organization/application', dataDB )
    return await response.data.data
}
export async function deleteOrganizationApplication(dataDB) {
    let response = await axios.delete('/api/v1/organization/application', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------


// ORGANIZATION - APPLICATION - ROLE API calls
// ------------------------------------------------------------------------------------------------
export async function getOrganizationApplicationRole(dataDB) {
    let response = await axios.get('/api/v1/organization/application/role', { params: dataDB })
    return await response.data.data
}
export async function putOrganizationApplicationRole(dataDB) {
    let response = await axios.post('/api/v1/organization/application/role', dataDB )
    return await response.data.data
}
export async function deleteOrganizationApplicationRole(dataDB) {
    let response = await axios.delete('/api/v1/organization/application/role', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// CLIENT

// ORGANIZATION LANGUAGE API calls
// ------------------------------------------------------------------------------------------------
export async function getLanguage(dataDB) {
    let response = await axios.get('/api/v1/language', { params: dataDB })
    return await response.data
}
export async function getLanguageKey() {
    let response = await axios.get('/api/v1/language/key')
    return await response.data.data
}
export async function putLanguageKey(dataDB) {
    let response = await axios.post('/api/v1/language/key', dataDB)
    return await response.data.data
}
export async function deleteLanguageKey(dataDB) {
    let response = await axios.delete('/api/v1/language/key', { params: dataDB })
    return await response.data.data
}
export async function getLanguageValue(dataDB) {
    let response = await axios.get('/api/v1/language/value', { params: dataDB })
    return await response.data.data
}
export async function putLanguageValue(dataDB) {
    let response = await axios.post('/api/v1/language/value', dataDB)
    return await response.data.data
}
export async function deleteLanguage(dataDB) {
    let response = await axios.delete('/api/v1/language/value', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// ORGANIZATION GROUP API calls
// ------------------------------------------------------------------------------------------------
export async function getOrganizationGroup(dataDB) {
    let getData = {organizationId: parseInt(dataDB)}
    let response = await axios.get('/api/v1/organization/group', { params: getData })
    return await response.data.data
}
export async function createOrganizationGroup(dataDB, organizationId) {
    let createData = { organizationId: parseInt(organizationId), EAV: dataDB }
    let response = await axios.post('/api/v1/organization/group', createData)
    return await response.data.data
}
export async function updateOrganizationGroupEAV(dataDB, modalItemId, organizationId) {
    // UPDATE id's -> INTEGER
    for(let one of dataDB) {
        one.organizationGroupId = parseInt(modalItemId)
        if(one.id) one.id = parseInt(one.id)
        one.organizationId = parseInt(organizationId)
    }
    let response = await axios.post('/api/v1/organization/group/eav', dataDB)
    return await response.data.data
}
export async function deleteOrganizationGroup(dataDB) {
    let response = await axios.delete('/api/v1/organization/group', { params: dataDB })
    return await response.data.data
}
export async function deleteOrganizationGroupField(dataDB) {
    let response = await axios.delete('/api/v1/organization/group/eav', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// ORGANIZATION USER API calls
// ------------------------------------------------------------------------------------------------
export async function getOrganizationUser(dataDB) {
    let getData = {organizationId: parseInt(dataDB)}
    let response = await axios.get('/api/v1/organization/user', { params: getData })
    return await response.data.data
}
export async function createOrganizationUser(dataDB, organizationId, email, password) {
    let createData = { organizationId: parseInt(organizationId), email: email, password: password, EAV: dataDB }
    let response = await axios.post('/api/v1/organization/user', createData)
    return await response.data.data
}
export async function updateOrganizationUserEAV(dataDB, modalItemId, organizationId) {
    // UPDATE id's -> INTEGER
    let arrayDB = []
    for(let one of dataDB) {
        if(one.id) {
            arrayDB.push({
                id: parseInt(one.id),
                organizationId: parseInt(organizationId),
                userId: parseInt(modalItemId),
                name: one.name,
                value: one.value
            })
        } else {
            arrayDB.push({
                organizationId: parseInt(organizationId),
                userId: parseInt(modalItemId),
                name: one.name,
                value: one.value
            })
        }
    }
    let response = await axios.post('/api/v1/organization/user/eav', arrayDB)
    return await response.data.data
}
export async function deleteOrganizationUser(dataDB) {
    let response = await axios.delete('/api/v1/organization/user', { params: dataDB })
    return await response.data.data
}
export async function deleteOrganizationUserField(dataDB) {
    let response = await axios.delete('/api/v1/organization/user/eav', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// ORGANIZATION GROUP - USER API calls
// ------------------------------------------------------------------------------------------------
export async function getGroupUsers(dataDB) {
    let response = await axios.get('/api/v1/organization/group/user', { params: dataDB })
    return await response.data.data
}
export async function updateGroupUsers(dataDB) {
    let response = await axios.post('/api/v1/organization/group/user', dataDB)
    return await response.data.data
}
export async function deleteGroupUser(dataDB) {
    let response = await axios.delete('/api/v1/organization/group/user', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// ORGANIZATION GROUP - ROLES API calls
// ------------------------------------------------------------------------------------------------
export async function getOrganizationAppRoles(dataDB) {
    let response = await axios.get('/api/v1/organization/group/application/role', { params: dataDB })
    return await response.data.data
}
export async function updateOrganizationAppRoles(dataDB) {
    let response = await axios.post('/api/v1/organization/group/application/role', dataDB)
    return await response.data.data
}
export async function deleteOrganizationAppRoles(dataDB) {
    let response = await axios.delete('/api/v1/organization/group/application/role', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------

// ORGANIZATION GROUP HIERARCHY API calls
// ------------------------------------------------------------------------------------------------
// not using because we get tree in getOrganization
// export async function getOrganizationGroupHierarchy(dataDB) {
//     let response = await axios.get('/api/v1/organization/group/hierarchy', { params: dataDB })
//     return await response.data.data
// }
export async function createOrganizationGroupHierarchy(dataDB) {
    let response = await axios.post('/api/v1/organization/group/hierarchy', dataDB)
    return await response.data.data
}
export async function updateOrganizationGroupHierarchy(dataDB) {
    let response = await axios.post('/api/v1/organization/group/hierarchy', dataDB)
    return await response.data.data
}
export async function deleteOrganizationGroupHierarchy(dataDB) {
    let response = await axios.delete('/api/v1/organization/group/hierarchy', { params: dataDB })
    return await response.data.data
}
// ------------------------------------------------------------------------------------------------