const initialState = {
    favorites: false
}

const dmsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CHANGE_STATE': 
            return { favorites: action.payload }
        default: {
            return state;
        }
    }
}

export default dmsReducer;