const initialState = {
    data: [],
    filter: '',
    arhitektiTemporaryDataTemplateChanges: [],
    arhitektiTemporaryDataTemplateChangesTEMP: [],
    arhitektiTemporaryDataTemplateChangesTEMPGUEST: [],
    arhitektiGuestCostBookData: {costBookId: '', header: [], rows: []}
}

const costBookReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DATA': 
            return { ...state, data: action.payload }
        case 'SET_FILTER': 
            return { ...state, filter: action.payload }
        case 'SET_TEMP_CHANGES_ARHITEKTI':
            for(let a of action.payload ) {
                a.hasOwnProperty('data') && a.data !== '' ? a.data = a.data.props.src : a.data = ''
            }
            return { ...state, arhitektiTemporaryDataTemplateChanges: action.payload }
        case 'SET_TEMP_CHANGES_ARHITEKTI_TEMP':
            for(let a of action.payload ) {
                a.hasOwnProperty('data') && a.data !== '' ? a.data = a.data.props.src : a.data = ''
            }
            return { ...state, arhitektiTemporaryDataTemplateChangesTEMP: action.payload }
        case 'SET_COSTBOOK_GUEST': 
            return { ...state, arhitektiGuestCostBookData: action.payload }
        case 'SET_TEMP_CHANGES_ARHITEKTI_TEMP_GUEST':
            for(let a of action.payload ) {
                a.hasOwnProperty('data') && a.data !== '' ? a.data = a.data.props.src : a.data = ''
            }
            return { ...state, arhitektiTemporaryDataTemplateChangesTEMPGUEST: action.payload }
        case 'LOGOUT':
            return { ...initialState }
        default: {
            return state;
        }
    }
}

export default costBookReducer;