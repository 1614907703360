import React, { useState, useEffect, useRef } from 'react';
import { Location } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux';

import setAuthToken from '../utils/setAuthToken'
import MainNavigationWhite from '../navigation/MainNavigationLeft'
import MainNavigation from '../navigation/MainNavigation'
import AuthAlert from '../components/common/AuthAlert'


const NavigationRoot = (props) => {
	const [ locationPath, setLocationPath ] = useState(window.location.pathname)
	const isMobile = useIsMobile()
	const mainNavSelected = useOrganizationNavigation()
	const dispatch = useDispatch()
	const width = window.innerWidth
	const filteredRoutes = props.filteredRoutes

	// scroll top on every link click
	// window.scrollTo(0, 0)

	// set auth token
	useAuthToken()

	// hook redirect if no route
	let redirect = useHandleRedirect(filteredRoutes, locationPath)

	// set isMobile on first render
	useIsMobile()
	// listen for window size
	useEffect(() => {
		const handleIsMobile = () => window.innerWidth <= 992 ? !isMobile && dispatch({ type: 'IS_MOBILE'}) : isMobile && dispatch({ type: 'NOT_MOBILE'})
		window.addEventListener("resize", handleIsMobile)
		return () => {
			window.removeEventListener("resize", handleIsMobile)
		};
	}, [width, dispatch, isMobile])


	const onLocationChange = (path) => {
		if(path !== locationPath) setLocationPath(path)
	}

	// hook is app online
	useIsOnline()

	// push to navigation history in store
	usePushNavHistory(locationPath, redirect)

	// examples

	// this hook render only once
	// useEffectOnce(() => {console.log('uEO: custom hook: FIRST TIME')})
	
	// Leave the app?
	// useEffect(() => {window.onbeforeunload = function(){return 'Leave the app?'}})

	// const handleBackNavigation = () => {if(locationPath !== '/') {window.history.go(-1)}}



    return(
		<div>
			<Location>
				{({ location })=> { onLocationChange(location.pathname)}}
			</Location>

			{/* if non authorized access to routes */}
			{redirect && <AuthAlert />}

			{/* Main navigation menu */}
			{mainNavSelected === 'white' ? 
				<MainNavigationWhite
					navLinks={ filteredRoutes }
					locationPath={ locationPath }
					isMobile={ isMobile }
					linkColor="#777"
				/>
				:
				<MainNavigation
					navLinks={ filteredRoutes }
					locationPath={ locationPath }
					isMobile={ isMobile }
					linkColor="#777"
				/>
			}

			{/* <div className="back_btn">
				<IconButton icon={palette.arrowLeft2} bgd={'secondary'} actionClick={() => handleBackNavigation()}></IconButton>
			</div> */}
		</div>
	)
}

// CUSTOM HOOKS

// Check for token hook
function useAuthToken() {
	const token = useSelector(state => state.user.token)
	setAuthToken(token)
}

// push to navigation history in store
function usePushNavHistory(path, redirect) {
	const dispatch = useDispatch()
	const navHistory = useSelector(state => state.app.history)
	if(navHistory[navHistory.length-1] !== path && !redirect) dispatch({ type: 'NAVIGATION_HISTORY', payload: path})
}

// select organization navigation
function useOrganizationNavigation() {
	const navigation = useSelector(state => state.user.Organization.navigation)
	return navigation
}

// hnadle redirect hook
function useHandleRedirect(filteredRoutes, locationPath) {
	let redirect = false
	let paths = filteredRoutes.map(( property ) => property.path)
	paths.push("/login")
	if(!paths.includes(locationPath)) redirect = true
	return redirect
}

// check if online hook
function useIsOnline() {
	const dispatch = useDispatch()
	const isOnline = useSelector(state => state.app.isOnline)
	navigator.onLine ? !isOnline && dispatch({ type: 'ONLINE'}) : isOnline && dispatch({ type: 'OFFLINE'})
}

// isMobile custom hook
function useIsMobile() {
	const isMobile = useSelector(state => state.app.isMobile)
	const dispatch = useDispatch()
	window.innerWidth <= 992 ? !isMobile && dispatch({ type: 'IS_MOBILE'}) : isMobile && dispatch({ type: 'NOT_MOBILE'})
	return isMobile
}

// active only on first render hook
// eslint-disable-next-line
function useEffectOnce(cb) {
    const once = useRef(false)
    useEffect(() => {
        if(!once.current) {
            cb();
            once.current = true
        }

    })
}

export default NavigationRoot;