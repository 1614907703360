// only for example change if you need it
import styled from 'styled-components';

import * as set from '../../style/Variables/StyleSet';
import * as color from '../../style/Variables/Colors';
import * as font from '../../style/Variables/Fonts';

// style of component
export const Input = styled.div`
    .input_wrp {
        position: relative;
        margin-top: 8px;
        height: 30px;
        margin-bottom: 6px;
        
        input {
            transition: border-color ease-in-out .2s,box-shadow ease-in-out .2s,-webkit-box-shadow ease-in-out .2s;
            color: ${color.black};
            background: transparent;
            border: 0;
            border-bottom: 1px solid ${color.greyLight};
            border-radius: 0;
            font-size: ${props => props.icon_button ? font.sFontSize: font.defaultFontSize};
            padding: ${props => props.input_simple ? "5px 0 0 0" : "5px 0 0 20px !important"};
            width: 100%;
            //margin-bottom: 2px;

            &:focus, &:hover {
                outline: none;
                border-color: ${color.primaryColor};
                color: ${color.black};
            }
        }
        input[type=number]::-webkit-inner-spin-button {
            opacity: 0;
        }
        input.has_danger { border-bottom: 1px solid ${color.danger}; }
        input.has_danger:focus { border-bottom: 1px solid ${color.primaryColor}; }
        .floating_label {
            position: absolute;
            pointer-events: none;
            top: 5px;
            left: 20px;
            ${set.transitionAll}
            margin: 0;
            opacity: 0;
            border: 0;
            border-radius: 0;
            font-family: ${font.mainFont};
            font-style: normal;
            font-weight: 400;
            font-size: ${font.defaultFontSize};
        }
        input:focus ~ .floating_label {
            opacity: 1;
            top: -8px;
            left: 0px;
            font-size: ${font.xxxsFontSize};
            color: ${color.primaryColor};
        }
        input:not(:placeholder-shown) ~ .floating_label {
            opacity: 1;
            top: -8px;
            left: 0px;
            font-size: ${font.xxxsFontSize};
            color: ${color.primaryColor};
        }
    }      
    .number_wrapper:after,
    .number_wrapper:before {
        position: absolute;
        right: 0;
        width: 15px;
        height: 11px;
        font-size: ${font.xsFontSize};
        pointer-events: none;
        background: transparent;
        line-height: 1;
        text-align: center;
        opacity: 0;
    }
    .number_wrapper:hover:after,
    .number_wrapper:hover:before {
        opacity: 1;
    }
    .number_wrapper:after {
        color: ${color.black};
        content: "\f106";
        margin-top: 4px;
        font-family: 'Line Awesome Free';
        font-weight: 900;
        line-height: 1.2;
    }
    .number_wrapper:before {
        color: ${color.black};
        content: "\f107";
        margin-bottom: 0;
        bottom: 3px;
        font-family: 'Line Awesome Free';
        font-weight: 900;
        line-height: 0.8;
    }
`
export const InputButton = styled.div`
    input {
        background: #fff;
        color: ${color.white};
        font-size: ${font.defaultFontSize};
        padding: 6px 20px 7px;
        margin-top: 10px;
        margin-bottom: 10px;
        line-height: 1;
        border: ${color.grey} 1px solid;

        ${set.radius60px}        
        ${set.transitionAll}
        ${set.setBorderBgdColor}
        
        &:hover { opacity: 0.8 }
        &:focus { outline: none; box-shadow: 0 0 3pt 1pt ${color.primaryColor}; }
        &:not(:last-child) { margin-right: 5px; }
    }
`
export const Textarea = styled.div`
    .input_wrp {
        position: relative;
        margin-top: 8px;
        margin-bottom: 6px;

        textarea {
            transition: border-color ease-in-out .2s,box-shadow ease-in-out .2s,-webkit-box-shadow ease-in-out .2s;
            color: ${color.black};
            border: 0;
            border-bottom: 1px solid ${color.greyLight};
            background: transparent;
            border-radius: 0;
            font-size: ${font.defaultFontSize};
            padding: 5px 0 0 20px !important;
            width: 100%;

            &:focus, &:hover {
                outline: none;
                border-color: ${color.primaryColor};
                color: ${color.black};
            }

            ${set.setScrollStyle}
        }
        .floating_label {
            position: absolute;
            pointer-events: none;
            top: 5px;
            left: 20px;
            transition: 0.2s ease all;
            margin: 0;
            opacity: 0;
            border: 0;
            border-radius: 0;
            font-family: ${font.mainFont};
            font-style: normal;
            font-weight: 400;
            font-size: ${font.defaultFontSize};
        }
        textarea:focus ~ .floating_label {
            opacity: 1;
            top: -8px;
            left: 0px;
            font-size: ${font.xxxsFontSize};
            color: ${color.primaryColor};
        }
        textarea:not(:placeholder-shown) ~ .floating_label {
            opacity: 1;
            top: -8px;
            left: 0px;
            font-size: ${font.xxxsFontSize};
            color: ${color.primaryColor};
        }
    }
    textarea.has_danger { border-bottom: 1px solid ${color.danger}; }
    textarea.has_danger:focus { border-bottom: 1px solid ${color.primaryColor}; }
    .required_field_msg {
        line-height: 0.2;
    }
`

export const Icon = styled.span`
    position: absolute;
    padding-top: ${props => props.input_date ? "4px" : props.date_icon ? "5px" : "6px"};
    color: ${color.grey};
    font-size: ${font.lFontSize};
    line-height: 1;
    z-index: 1;
`
export const Checkbox = styled.div`
    height: 18px;
    label input {
        display: none;
    }
    label span {
        height: 18px;
        width: 18px;
        border: 1px solid ${color.greyMedium};
        display: inline-block;
        position: relative;
        ${set.radius6px}
    }
    [type=checkbox]:checked + span { border: 1px solid ${color.secondaryColor}; }
    [type=checkbox]:checked + span:before {
        border: solid ${color.secondaryColor};
        top: 50%;
        left: 50%;
        margin-left: -2px;
        margin-top: -6px;
        width: 5px;
        height: 10px;
        border-width: 0 2px 2px 0!important;
        transform: rotate(45deg);
        content: '';
        position: absolute;
    }
    .checkbox_label {
        display: inline-block;
        padding-left: 7px;
        font-size: ${font.defaultFontSize};
        color: #a1a6b3;
        margin-bottom: 0;
        vertical-align: top;
        line-height: 1.5;
    }
    .checkbox_label_left {
        display: inline-block;
        padding-right: 7px;
        font-size: ${font.defaultFontSize};
        color: #a1a6b3;
        margin-bottom: 0;
        vertical-align: top;
        line-height: 1.5;
    }
`
export const Check = styled.span`
    border: 1px solid ${color.secondaryColor};
    height: 18px;
    width: 18px;
    display: block;
    display: inline-block;
    vertical-align: text-bottom;
    ${set.radius6px}

    svg {
        margin-top: -12px;
        width: 16px;
        height: 16px;
        color: ${color.secondaryColor};
    }
`;

export const RadioBtn = styled.div`
    height: 18px;
    label input { display: none; }
    label span {
        height: 18px;
        width: 18px;
        border: 1px solid ${color.greyMedium};
        display: inline-block;
        position: relative;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border-radius: 50%;
    }
    [type=radio]:checked + span { border: 1px solid ${color.secondaryColor}; }
    [type=radio]:checked + span:before {
        content: '';
        position: absolute;
        background: ${color.secondaryColor};
        top: 4px;
        left: 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
    }
    .radio_label {
        display: inline-block;
        padding-left: 7px;
        font-size: ${font.defaultFontSize};
        color: #a1a6b3;
        margin-bottom: 0;
        vertical-align: top;
    }
`
export const RadioCheck = styled.span`
    border: 1px solid ${color.secondaryColor};
    height: 18px;
    width: 18px;
    display: block;
    display: inline-block;
    vertical-align: text-bottom;
    ${set.radius6px}
    
    svg {
        margin-top: -12px;
        width: 16px;
        height: 16px;
        color: ${color.secondaryColor};
    }
`;

export const Select = styled.div`
    margin-top: 8px;
    position: relative;
    margin-bottom: 6px;
    text-align: left;

    &:focus { outline: none !important; }

    span { padding-top: 2px; }

    span[class$=-indicatorSeparator] {
        margin-bottom: 4px;
        margin-top: 4px;
    }
    .css-79bx65 { padding: 0 5px 0 20px; line-height: 1.3; }
    .css-1g6gooi { padding: 0; }

    div[class$=-indicatorSeparator],
    div[class$=-indicatorContainer],
    div[class$=-indicatorContainer]:hover { padding: 3px; color: ${color.greyMedium}; }
    div[class$=-control] { min-height: auto; height: 22px; border-bottom: 1px solid ${color.greyLight}; }
    div[class$=-control]:hover, div[class$=-control]:focus { border-bottom: 1px solid ${color.primaryColor}; }
    div[class$=-Input] { padding-bottom: 0; padding-top: 0; }

    div[class$=-menu] div[class$=-MenuList] {
        ${set.setScrollStyle}
    }
    &.has_danger div[class$=-control] { border-bottom: 1px solid ${color.danger}; }
    .required_field_msg {
        line-height: 1.5;
    }
`;

export const Datepicker = styled.div`
    .input_wrp {
        position: relative;
        margin-top: 8px;
        margin-bottom: 6px;
        height: ${props => props.datepicker_open ? "auto" : "30px"};

        .react-datepicker-wrapper {
            width: 100%;
            margin-top: -2px;
            display: block;
        }
        .react-datepicker {
            border-radius: 0;
            border: 1px solid ${color.greyLight};
            font-family: ${font.mainFont};
            width: ${props => props.time ? "auto" : "100%"};
            min-width: ${props => props.date_time ? "auto" : props.time ? "auto" : "250px"};
        }
        .react-datepicker__header {
            background-color: ${color.white};
            border-bottom: 1px solid ${color.greyLight};
        }
        .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header { font-weight: 100; }
        .react-datepicker__day--today, .react-datepicker__month-text--today, .react-datepicker__quarter-text--today { font-weight: 500; }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
            border: 1px solid ${color.white};
            padding: 4px 9px;
            display: inline-block;
            min-width: 40px;
            margin: 0;
        }
        .react-datepicker__day, .react-datepicker__day-name {
            width: 14%;
            min-width: auto;
        }
        .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
            border: 1px solid ${color.primaryColor};
            background: ${color.white};
            border-radius: 0;
        }
        .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range {
            border: 1px solid ${color.white};
            background: ${color.primaryColor};
            border-radius: 0;
        }
        .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected {
            border: 1px solid ${color.white};
            background: ${color.primaryColor};
            border-radius: 0;
        }
        .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__quarter-text--keyboard-selected:hover {
            border: 1px solid ${color.white};
            background: ${color.primaryColor};
            border-radius: 0;
        }
        .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover, .react-datepicker__quarter-text--selected:hover, .react-datepicker__quarter-text--in-selecting-range:hover, .react-datepicker__quarter-text--in-range:hover {
            border: 1px solid ${color.white};
            background: ${color.primaryColor};
            border-radius: 0;
        }
        .react-datepicker__triangle { display: none; }
        .react-datepicker-popper { width: 100%; transform: translate3d(0px, 24px, 0px) !important; }
        .react-datepicker-popper[data-placement^="bottom"] { margin-top: 0; z-index: 9; }
        .react-datepicker__month { margin: 0; }
        .react-datepicker__month-container { width: ${props => props.date_time ? "calc(100% - 80px)" : "100%"}; }
        .react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
            right: ${props => props.date_time ? "90px" : "20px"};
        }
        .react-datepicker__time-container {
            border-left: 1px solid ${color.greyLight};
            width: ${props => props.date_time ? "80px" : "auto"};
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
            ${set.setScrollStyle}
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
            border: 1px solid ${color.white};
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
            background-color: ${color.white};
            border: 1px solid ${color.primaryColor};
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
            background-color: ${color.primaryColor};
            border: 1px solid ${color.primaryColor};
            color: ${color.white};
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
            background-color: ${color.primaryColor};
            border: 1px solid ${color.primaryColor};
            color: ${color.white};
        }
        .react-datepicker--time-only .react-datepicker__time-box {
            border-radius: 0;
        }
        .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
            width: 100%;
        }
        .react-datepicker__header--time {
            padding-top: 0;
            padding-bottom: 0;
        }
        .react-datepicker__header--time {
            display: none;
        }
        input {
            transition: border-color ease-in-out .2s,box-shadow ease-in-out .2s,-webkit-box-shadow ease-in-out .2s;
            color: ${color.black};
            background: ${color.white};
            border: 0;
            border-bottom: 1px solid ${color.greyLight};
            border-radius: 0;
            font-size: ${font.defaultFontSize};
            padding: 5px 0 0 20px !important;
            width: 100%;

            &:focus, &:hover {
                outline: none;
                border-color: ${color.primaryColor};
                color: ${color.black};
            }
        }
        .react-datepicker__close-icon::after {
            background-color: transparent;
            color:  ${color.danger};
            font-size: ${font.xxlFontSize};
            line-height: 0;
        }
        input.has_danger { border-bottom: 1px solid ${color.danger}; }
        input.has_danger:focus { border-bottom: 1px solid ${color.primaryColor}; }
        .date_floating_label {
            position: absolute;
            pointer-events: none;
            top: 5px;
            left: 20px;
            transition: 1s ease all;
            margin: 0;
            opacity: 0;
            border: 0;
            border-radius: 0;
            font-family: ${font.mainFont};
            font-style: normal;
            font-weight: 400;
            font-size: ${font.defaultFontSize};
        }
        .date_floating_label.show {
            position: absolute;
            pointer-events: none;
            transition: 0.2s ease all;
            margin: 0;
            border: 0;
            border-radius: 0;
            font-family: ${font.mainFont};
            font-style: normal;
            font-weight: 400;
            opacity: 1;
            top: -8px;
            left: 0px;
            font-size: ${font.xxxsFontSize};
            color: ${color.primaryColor};
        }
    }
`;
export const Search = styled.input`
    width: 100%;
    border: 1px solid ${color.grey};
    padding: 8px 15px 8px !important;
    font-size: ${font.xsFontSize};
    border-radius: 40px;
    height: 30px;
    transition: 0.2s ease all;

    &:focus, &:hover {
        outline: none;
        border: 1px solid ${color.primaryColor};
    }
`;
export const Question = styled.h3`
    color: ${color.primaryColor};
    font-weight: normal;
    font-size: ${font.xxxsFontSize};
    margin: 0;
    margin-bottom: -8px;
    &.options {
        margin-bottom: 2px;
    }
`;
export const SelectOption = styled.span`
    margin-top: 10px;
    margin-bottom: 6px;
    display: block;
`;
export const MultiEmail = styled.span`
    margin-top: 8px;
    position: relative;
    margin-bottom: 6px;
    text-align: left;

    .react-multi-email {
        transition: border-color ease-in-out .2s,box-shadow ease-in-out .2s,-webkit-box-shadow ease-in-out .2s;
        background: transparent;
        border: 0;
        border-bottom: 1px solid ${color.greyLight};
        border-radius: 0;
        padding: 5px 0 0 0;
        width: 100%;

        &.empty > span[data-placeholder] {
            font-size: ${font.defaultFontSize};
            line-height: 21px;
            color: rgb(117,117,117);
            left: 0;
            top: 16px;
            padding: 0;
            min-height: auto;
            font-weight: 400;
        }
        input {
            min-height: auto;
            font-size: ${font.defaultFontSize};
            line-height: 21px;
            color: ${color.black};
            font-weight: 400;

            &:hover, &:focus { border-bottom: 1px solid ${color.primaryColor}; }
        }
        [data-tag] {
            border-radius: 50px;
            padding: 3px 9px 4px;
        }
    }
    &.has_danger div[class$=-control] { border-bottom: 1px solid ${color.danger}; }
    .required_field_msg {
        line-height: 1.5;
    }
`;
