import React, { useState, useEffect } from 'react';
import * as Styled from "./style";

const NotificationDropdownLeft = ({ align, bgd, icon, dropdownData, tooltip }) => {
    const [ displayDropdown, setDisplayDropdown ] = useState('none')
    useEffect(() => {
        const handleClick = () => {
            if(displayDropdown === 'block') setDisplayDropdown('none')
        }
        window.addEventListener("click", handleClick)
        return () => {
            window.removeEventListener('click', handleClick)
        };
    }, [displayDropdown])

    return(
        <Styled.NotificationDropdown className={align}>
            <Styled.NotificationDropdownButton icon_dropdown onClick={() => displayDropdown === 'none' ? setDisplayDropdown('block') : setDisplayDropdown('none')} type="button" name="button" className={bgd} title={tooltip}>{icon}</Styled.NotificationDropdownButton>
                <div className={displayDropdown !== 'none' ? 'dropdown_menu dropdown_menu_noti dropdown_active' : 'dropdown_menu dropdown_menu_noti'}>
                    <div className="dropdown_before"></div>
                    <ul className="dropdown_list vertical_scroll">
                        {dropdownData.map(function(d, idx){
                            return (
                                <li key={idx} className="dropdown_item dropdown_item_noti">
                                    <span className=""></span>
                                    <span className={'dropdown_link notification ' + d.notiBgd} onClick={d.linkAction}>
                                        <div className={'noti_icon ' + d.iconColor}>{d.icon}</div>
                                        <div className="noti_title">{d.title}</div>
                                        <div className="noti_desc">{d.desc}</div>
                                        <div className="noti_time">{d.time}</div>
                                    </span>
                                </li>
                            )
                        })}
                    </ul>
                </div>
        </Styled.NotificationDropdown>
    )
}

export default NotificationDropdownLeft;