// Font size
export const defaultFontSize = '14px';
export const titleFontSize = '16px';

export const xxxsFontSize = '10px';
export const xxsFontSize = '11px';
export const xsFontSize = '12px';
export const sFontSize = '13px';
export const mFontSize = '15px';
export const lFontSize = '18px';
export const xlFontSize = '20px';
export const xxlFontSize = '22px';
export const xxxlFontSize = '24px';
export const xxxxlFontSize = '26px';

// Font family
export const mainFont = "'Open sans', sans-serif";
export const secondaryFont = "'Roboto', sans-serif";
export const thirdFont = "'Montserrat', sans-serif";