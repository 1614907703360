import React from 'react';
import * as Styled from "./style";

const Checkbox = ({ onChange, value, checked, label }) => (
    <Styled.Checkbox>
        <label>
            <input type="checkbox" onChange={onChange} value={value} checked={checked}></input>
            <span></span>
            <p className="checkbox_label">{label}</p>
        </label>
    </Styled.Checkbox>
)

export default Checkbox;