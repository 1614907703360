import React from 'react';
import * as Styled from "./style";

const Button = ({ tabIndex, disabled, actionClick, bgd, dataFor, btnText, tooltip }) => {
    
    return(
        <Styled.Button text_button tabIndex={tabIndex} disabled={disabled} type="button" name="button" onClick={actionClick} className={bgd} data-for={dataFor} title={tooltip}>{btnText}</Styled.Button>
    )
}

export default Button;