import React from 'react';
import ReactTooltip from 'react-tooltip'
import * as Styled from "./style";

const Tooltip = ({ id, place, type, effect, bgColor,  tooltipText }) => {
    
    return(
        <Styled.Tooltip>
            <ReactTooltip id={id} place={place} type={type} effect={effect} className={bgColor}>
                <span>{tooltipText}</span>
            </ReactTooltip>
        </Styled.Tooltip>
    )
}

export default Tooltip;