import React from 'react';
import * as Styled from "./style";

const Input = ({ icon, disabled, requiredField, errorBottomLine, type, name, tabIndex, autoComplete, value, placeholder, defaultValue, onChange, min, max, autoFocus }) => {

    return(
        <Styled.Input>
            <div className={"input_wrp" + (type === 'number'  ? ' number_wrapper' : '')} >
                <Styled.Icon>{icon}</Styled.Icon>
                <input
                    disabled={disabled}
                    className={"" + (requiredField || errorBottomLine ? 'has_danger' : '')}
                    type={type}
                    name={name}
                    tabIndex={tabIndex}
                    autoComplete={autoComplete}
                    value={value}
                    placeholder={placeholder}
                    defaultValue={defaultValue}
                    onChange={onChange}
                    // onClick={() => console.log('onClick value: ', value)}
                    min={min}
                    max={max}
                    autoFocus={autoFocus}
                />
                <span className="floating_label">{placeholder}</span>
                { requiredField && <div className="required_field_msg">Required field.</div> }
            </div>
        </Styled.Input>
    )
}

export default Input;