import styled from 'styled-components';

import * as color from '../../style/Variables/Colors';
import * as font from '../../style/Variables/Fonts';
import * as set from '../../style/Variables/StyleSet';

export const Text = styled.h1`
    font-size: ${font.defaultFontSize};
    margin: 0;        
    font-family: ${font.mainFont};
    color: ${color.black};
    text-align: left;
    ${set.noWrap}
`;
export const Title = styled.h1`
    font-size: ${props => props.sub_title ? font.sFontSize : font.titleFontSize};
    line-height: ${props => props.sub_title ? "20px" : "22px"};
    font-weight: ${props => props.sub_title ? "300" : "500"};
    margin: 0;        
    font-family: ${font.mainFont};
    color: ${color.black};
    text-align: left;

    i {
        margin-right: 5px;
        font-size: ${font.lFontSize};
        vertical-align: bottom;
    }

    ${set.noWrap}
    ${set.setColor}
`;

export const IconTitle = styled.span`
    color: ${color.grey};
    font-size: ${font.xxxxlFontSize};
    line-height: 16px;
`;

export const TitleWithBtn = styled.div`
    display: table;
    position: relative;
    table-layout: fixed;
    width: 100%;
`
export const TitleBox = styled.div`
    display: table-cell;
    text-align: left;
    vertical-align: middle;
    width: 100%;
`
export const BtnBox = styled.div`
    width: 100px;
    text-align: right;
    vertical-align: middle;
    display: table-cell;

    .angle_btn {
        background: transparent;
        border: none;
        font-size: ${font.titleFontSize};
    }
    &.back_btn_box {
        button {
            float: right;
            font-size: ${font.xxxxlFontSize};

            @media (max-width: 992px) {
                display: none;
            }
        }
    }
    &.path_back_btn_box {
        button {
            float: right;
            margin-left: 5px;
        }
        button:last-child {
            font-size: ${font.xxxxlFontSize};
        }
        @media (max-width: 992px) {
            min-width: 35px;
        }
    }
`

export const TitleModalExpand = styled.div`
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 10px;
    margin-bottom: 10px;
    
    ${set.setBgdLight}
`

export const Tooltip = styled.span`
    .__react_component_tooltip {
        padding: 5px 9px;
        &.place-bottom {
            margin-top: 15px;
        }
    }
    .type-warning, .type-success, .type-error, .type-info, .type-light, .type-dark {
        color: ${color.white};
        font-weight: 400;
        ${set.defaultShadow}
    }

    .type-warning { background-color: ${color.warning}; }
    .type-warning.place-top:after { border-top-color: ${color.warning}; }
    .type-warning.place-right:after { border-right-color: ${color.warning}; }
    .type-warning.place-bottom:after { border-bottom-color: ${color.warning}; }
    .type-warning.place-left:after { border-left-color: ${color.warning}; }
    
    .type-success { background-color: ${color.success}; }
    .type-success.place-top:after { border-top-color: ${color.success}; }
    .type-success.place-right:after { border-right-color: ${color.success}; }
    .type-success.place-bottom:after { border-bottom-color: ${color.success}; }
    .type-success.place-left:after { border-left-color: ${color.success}; }
    
    .type-error { background-color: ${color.danger}; }
    .type-error.place-top:after { border-top-color: ${color.danger}; }
    .type-error.place-right:after { border-right-color: ${color.danger}; }
    .type-error.place-bottom:after { border-bottom-color: ${color.danger}; }
    .type-error.place-left:after { border-left-color: ${color.danger}; }
    
    .type-info { background-color: ${color.primaryColor}; }
    .type-info.place-top:after { border-top-color: ${color.primaryColor}; }
    .type-info.place-right:after { border-right-color: ${color.primaryColor}; }
    .type-info.place-bottom:after { border-bottom-color: ${color.primaryColor}; }
    .type-info.place-left:after { border-left-color: ${color.primaryColor}; }
    
    .type-light { color: ${color.black}; background-color: ${color.white}; }
    .type-light.place-top:after { border-top-color: ${color.white}; }
    .type-light.place-right:after { border-right-color: ${color.white}; }
    .type-light.place-bottom:after { border-bottom-color: ${color.white}; }
    .type-light.place-left:after { border-left-color: ${color.white}; }

    .type-dark { background-color: ${color.secondaryColor}; }
    .type-dark.place-top:after { border-top-color: ${color.secondaryColor}; }
    .type-dark.place-right:after { border-right-color: ${color.secondaryColor}; }
    .type-dark.place-bottom:after { border-bottom-color: ${color.secondaryColor}; }
    .type-dark.place-left:after { border-left-color: ${color.secondaryColor}; }

    .red, .orange, .yellow, .green, .mint, .aqua, .blue, .purple, .pink, .ocher, .grey {
        color: ${color.white};
        font-weight: 400;
    }
    .red { background-color: ${color.mRed}; }
    .red.place-top:after { border-top-color: ${color.mRed}; }
    .red.place-right:after { border-right-color: ${color.mRed}; }
    .red.place-bottom:after { border-bottom-color: ${color.mRed}; }
    .red.place-left:after { border-left-color: ${color.mRed}; }

    .orange { background-color: ${color.mOrange}; }
    .orange.place-top:after { border-top-color: ${color.mOrange}; }
    .orange.place-right:after { border-right-color: ${color.mOrange}; }
    .orange.place-bottom:after { border-bottom-color: ${color.mOrange}; }
    .orange.place-left:after { border-left-color: ${color.mOrange}; }

    .yellow { background-color: ${color.mYellowDark}; }
    .yellow.place-top:after { border-top-color: ${color.mYellowDark}; }
    .yellow.place-right:after { border-right-color: ${color.mYellowDark}; }
    .yellow.place-bottom:after { border-bottom-color: ${color.mYellowDark}; }
    .yellow.place-left:after { border-left-color: ${color.mYellowDark}; }

    .green { background-color: ${color.mGreen}; }
    .green.place-top:after { border-top-color: ${color.mGreen}; }
    .green.place-right:after { border-right-color: ${color.mGreen}; }
    .green.place-bottom:after { border-bottom-color: ${color.mGreen}; }
    .green.place-left:after { border-left-color: ${color.mGreen}; }

    .mint { background-color: ${color.mMint}; }
    .mint.place-top:after { border-top-color: ${color.mMint}; }
    .mint.place-right:after { border-right-color: ${color.mMint}; }
    .mint.place-bottom:after { border-bottom-color: ${color.mMint}; }
    .mint.place-left:after { border-left-color: ${color.mMint}; }

    .aqua { background-color: ${color.mAqua}; }
    .aqua.place-top:after { border-top-color: ${color.mAqua}; }
    .aqua.place-right:after { border-right-color: ${color.mAqua}; }
    .aqua.place-bottom:after { border-bottom-color: ${color.mAqua}; }
    .aqua.place-left:after { border-left-color: ${color.mAqua}; }

    .blue { background-color: ${color.mBlue}; }
    .blue.place-top:after { border-top-color: ${color.mBlue}; }
    .blue.place-right:after { border-right-color: ${color.mBlue}; }
    .blue.place-bottom:after { border-bottom-color: ${color.mBlue}; }
    .blue.place-left:after { border-left-color: ${color.mBlue}; }

    .purple { background-color: ${color.mPurple}; }
    .purple.place-top:after { border-top-color: ${color.mPurple}; }
    .purple.place-right:after { border-right-color: ${color.mPurple}; }
    .purple.place-bottom:after { border-bottom-color: ${color.mPurple}; }
    .purple.place-left:after { border-left-color: ${color.mPurple}; }

    .pink { background-color: ${color.mPink}; }
    .pink.place-top:after { border-top-color: ${color.mPink}; }
    .pink.place-right:after { border-right-color: ${color.mPink}; }
    .pink.place-bottom:after { border-bottom-color: ${color.mPink}; }
    .pink.place-left:after { border-left-color: ${color.mPink}; }

    .ocher { background-color: ${color.mOcher}; }
    .ocher.place-top:after { border-top-color: ${color.mOcher}; }
    .ocher.place-right:after { border-right-color: ${color.mOcher}; }
    .ocher.place-bottom:after { border-bottom-color: ${color.mOcher}; }
    .ocher.place-left:after { border-left-color: ${color.mOcher}; }

    .grey { background-color: ${color.mGrey}; }
    .grey.place-top:after { border-top-color: ${color.mGrey}; }
    .grey.place-right:after { border-right-color: ${color.mGrey}; }
    .grey.place-bottom:after { border-bottom-color: ${color.mGrey}; }
    .grey.place-left:after { border-left-color: ${color.mGrey}; }
`

export const Path = styled.span`
    font-size: ${font.defaultFontSize};
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    display: block;
    overflow-x: scroll;
`