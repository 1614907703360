import React from 'react';
import axios from './API';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../store/appStore/actions/userActions'
import { toast } from 'react-toastify';
import AlertSimple from '../components/alert/AlertSimple'
import * as palette from '../style/Variables';


function ResponseInterceptor() {
    let showTokenMsg = 0
    const dispatch = useDispatch()
    // console.log('INTERCEPTOR')

    // enable request interceptor
    axios.interceptors.response.use(
        response => successHandler(response),
        error => errorHandler(error)
    )
  
    // function which will check if the global handler should be used or not
    const isHandlerEnabled = (config={}) => {
        // console.log('INTERCEPTOR isHandlerEnabled')
        return config.hasOwnProperty('handlerEnabled') && !config.handlerEnabled ? 
        false : true
    }

    // error response handler
    const errorHandler = (error) => {
        // console.log('INTERCEPTOR ERROR')
        if (isHandlerEnabled(error.config)) {
            // Handle errors
            if(JSON.parse(error.response.data.message).error.name === 'TokenExpiredError') {
                // show token message only once
                showTokenMsg++
                if(showTokenMsg > 1) return
                toast.error((<AlertSimple icon={palette.x} text='Token expired, please login again!' />), { autoClose: 3000 })
                setTimeout(() => logoutUser(dispatch), 3000)
            }
        }
        return Promise.reject({ ...error })
    }
  
    // success response handler
    const successHandler = (response) => {
        if (isHandlerEnabled(response.config)) {
        // Handle responses
            // console.log('I SUCCESS', response)            
        }
        return response
    }

  return null
}

export default ResponseInterceptor;
