import styled from 'styled-components'

import * as set from '../style/Variables/StyleSet';
import * as color from '../style/Variables/Colors';
import * as font from '../style/Variables/Fonts';

export const containerWrapper = styled.div`
    width: calc(100% - 60px);
    margin: 0 0 0 60px;
    border-bottom: 1px solid ${color.secondaryColor};
`;

export const SmallNavigation = styled.div`
    
`;
export const LogoStyle = styled.img`
    width: 100%;
    cursor: pointer;
`
export const TopNav = styled.div`
    display: table;
    width: 100%;
    height: 50px;

    .top_nav_box {
        padding: 0 15px;
        display: table-cell;
        width: calc(100% - 150px);
        overflow: scroll;
        white-space: nowrap;
        vertical-align: middle;
        font-size: ${font.defaultFontSize};

        button {
            color: ${color.black};
            padding: 10px 7px;
            ${set.transitionAll}

            i, svg {
                padding-left: 3px;
            }
            svg {
                color: ${color.grey};
            }
            .top_btn_text {
                vertical-align: middle;
            }
        }
        button:hover {
            color: ${color.secondaryColor};
        }
    }
    @media (max-width: 992px) {
        .top_nav_box {
            padding: 0 10px;
            width: calc(100% - 110px);

            button {
                height: 30px;
                width: 30px;
                padding: 0;

                i, svg {
                    padding-left: 0;
                }
                .top_btn_text {
                    display: none;
                }
            }
        }
    }
`

// MOBILE
export const MainNavMobile = styled.div`
@media (max-width: 992px) {
    .mobile_nav_btn {
        float: right;
        font-size: ${font.xlFontSize};
        border: none;
        line-height: 0;
        background: transparent;
        color: ${color.mGreyDark};
        height: 40px;
        width: 40px;
    }
    .mobile_nav__open {
        height: 100%;
        position: fixed;
        z-index: 99;
        top: 0;
        right: 0;
        background-color: white;
        overflow-x: hidden;
        transition: .5s;
        padding-top: 40px;
        width: 250px;

        ${set.defaultShadow}

        .mobile_nav_close {
            font-size: 36px;
            float: right;
            color: ${color.black};
            position: absolute;
            right: 0;
            top: 0;
            font-weight: 100;
            border: none;
            background: transparent;
            line-height: 0;
            height: 40px;
            width: 40px;
        }
        .user_initials_btn {
            background: ${color.primaryColor};
            color: ${color.white};
            text-transform: uppercase;
            font-size: ${font.xsFontSize};
            padding: 0;
            height: 30px;
            width: 30px;
            display: inline-block;
            font-weight: 500;
            vertical-align: middle;
            padding-top: 6px;
            text-align: center;
            position: absolute;
            top: 10px;
            left: 10px;
            ${set.radius100}
        }
        .user_name {
            font-size: ${font.sFontSize};
            font-weight: 500;
            padding-left: 10px;
        }
        .logout_link_mobile {
            width: 100%;
            padding: 10px 20px;
            outline: 0;
            color: ${color.black};
            font-size: ${font.sFontSize};
            display: block;
            font-weight: 400;
            cursor: pointer;
            border-top: 1px solid rgba(0,0,0,.1);
            i {
                color: ${color.grey};
                padding-right: 5px;
                font-size: ${font.mFontSize};
            }
        }
        .mobile_nav__open_links {
            
        }
    }
}
`;
export const MainNavMobileOpenLinks = styled.div`
@media (max-width: 992px) {
    display: table;
    table-layout: fixed;
    width: 100%;
    margin: 0;
    text-decoration: none;
    position: relative;

    &.border_link {
        border-top: 1px solid rgba(0,0,0,.1);
    }
    i {
        color: ${color.mGrey};
        padding-right: 5px;
        font-size: 17px;
        line-height: 1.3;
        vertical-align: revert;
    }
    .bgdColor {
        &.primary i { color: ${color.primaryColorDark}; }
        &.red i { color: ${color.mRedDark}; }
        &.orange i { color: ${color.mOrangeDark}; }
        &.yellow i { color: ${color.mYellowDark}; }
        &.green i { color: ${color.mGreenDark}; }
        &.mint i { color: ${color.mMintDark}; }
        &.aqua i { color: ${color.mAquaDark}; }
        &.blue i { color: ${color.mBlueDark}; }
        &.purple i { color: ${color.mPurpleDark}; }
        &.pink i { color: ${color.mPinkDark}; }
        &.ocher i { color: ${color.mOcherDark}; }
        &.grey i { color: ${color.mGreyDark}; }
    }
    a {
        width: 100%;
        padding: 10px 20px;
        outline: 0;
        color: ${color.black};
        font-size: ${font.sFontSize};
        display: block;
        font-weight: 400;
    }
    &.active {
        .bgdColor {
            background: ${color.mGreyLight};
            &.primary { background: ${color.primaryColor}; }
            &.red { background: ${color.mRed}; }
            &.orange { background: ${color.mOrange}; }
            &.yellow { background: ${color.mYellowDark}; }
            &.green { background: ${color.mGreen}; }
            &.mint { background: ${color.mMint}; }
            &.aqua { background: ${color.mAqua}; }
            &.blue { background: ${color.mBlue}; }
            &.purple { background: ${color.mPurple}; }
            &.pink { background: ${color.mPink}; }
            &.ocher { background: ${color.mOcher}; }
            &.grey { background: ${color.mGrey}; }
        }
        i, a {
            color: ${color.white} !important;
        }
    }
}
`;
export const SideNav = styled.div`
    z-index: 99;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;

    .top_logo_box {
        display: block;
        width: 60px;

        img {
            margin: 5px auto 0;
            width: 100%;
            width: 45px;
            height: 45px;
            display: block;
            ${set.radius100}
            
            ${set.defaultShadow}

            &:hover {
                ${set.hardShadow}
            }
        }
    }
    
    .nav_box {
        max-width: 60px;
        height: 100%;
        background: ${color.white};
        overflow-x: hidden;
        overflow-y: scroll;

        ${set.defaultShadow}

        .main_nav_btn {
            display: block;
            border: none;
            background: transparent;
            height: 50px;
            ${set.noWrap}
            ${set.transitionAll}

            i {
                width: 40px;
                height: 40px;
                display: inline-block;
                background: ${color.secondaryColor};
                color: ${color.white};
                font-size: ${font.lFontSize};
                line-height: 40px;
                margin-right: 7px;
                ${set.radius100}
                ${set.defaultShadow}
                ${set.transitionAll}
            }
            svg {
                width: 40px;
                height: 40px;
                padding: 11px;
                display: inline-block;
                background: ${color.secondaryColor};
                color: ${color.white};
                font-size: ${font.lFontSize};
                line-height: 40px;
                margin-right: 7px;
                ${set.radius100}
            }
            .btn_text {
                padding-right: 10px;
                font-size: ${font.defaultFontSize};
            }
            
            &.primary { i { background: ${color.primaryColor}; } }
            &.red { i { background: ${color.mRed}; } }
            &.orange { i { background: ${color.mOrange}; } }
            &.yellow { i { background: ${color.mYellowDark}; } }
            &.green { i { background: ${color.mGreen}; } }
            &.mint { i { background: ${color.mMint}; } }
            &.aqua { i { background: ${color.mAqua}; } }
            &.blue { i { background: ${color.mBlue}; } }
            &.purple { i { background: ${color.mPurple}; } }
            &.pink { i { background: ${color.mPink}; } }
            &.ocher { i { background: ${color.mOcher}; } }
            &.grey { i { background: ${color.mGrey}; } }
        }
        .main_nav_btn:hover {
            i {
                background: ${color.secondaryColorLight};
                color: ${color.black};

                ${set.hardShadow}
            }
            
            &.primary { i { background: ${color.primaryColorLight}; } }
            &.red { i { background: ${color.mRedLight}; } }
            &.orange { i { background: ${color.mOrangeLight}; } }
            &.yellow { i { background: ${color.mYellow}; } }
            &.green { i { background: ${color.mGreenLight}; } }
            &.mint { i { background: ${color.mMintLight}; } }
            &.aqua { i { background: ${color.mAquaLight}; } }
            &.blue { i { background: ${color.mBlueLight}; } }
            &.purple { i { background: ${color.mPurpleLight}; } }
            &.pink { i { background: ${color.mPinkLight}; } }
            &.ocher { i { background: ${color.mOcherLight}; } }
            &.grey { i { background: ${color.mGreyLight}; } }
        }
    }
`
export const SubNavMobile = styled.div`
@media (max-width: 992px) {

    .sub_nav_mobile {
        height: 40px;
        padding: 0;
        background: ${color.white};
        text-align: left;

        ${set.defaultShadow}

        .sub_nav_wrapper {
            width: calc(100% - 40px);
            display: table;
            position: absolute;
            height: 40px;

            &.primary { background: ${color.primaryColor}; }
            &.red { background: ${color.mRed}; }
            &.orange { background: ${color.mOrange}; }
            &.yellow { background: ${color.mYellowDark}; }
            &.green { background: ${color.mGreen}; }
            &.mint { background: ${color.mMint}; }
            &.aqua { background: ${color.mAqua}; }
            &.blue { background: ${color.mBlue}; }
            &.purple { background: ${color.mPurple}; }
            &.pink { background: ${color.mPink}; }
            &.ocher { background: ${color.mOcher}; }
            &.grey { background: ${color.mGrey}; }
        }
        a {
            color: ${color.white};
            font-weight: 400;
            font-size: ${font.xlFontSize};
            text-transform: initial;
            display: block;
        }
    }
    .material-icons {
        line-height: 0;
        padding: 8px;
    }
}
`;
export const SubNavigationLinkMobile = styled.div`
    &.primary { background: ${color.primaryColor}; }
    &.red { background: ${color.mRed}; }
    &.orange { background: ${color.mOrange}; }
    &.yellow { background: ${color.mYellowDark}; }
    &.green { background: ${color.mGreen}; }
    &.mint { background: ${color.mMint}; }
    &.aqua { background: ${color.mAqua}; }
    &.blue { background: ${color.mBlue}; }
    &.purple { background: ${color.mPurple}; }
    &.pink { background: ${color.mPink}; }
    &.ocher { background: ${color.mOcherDark}; }
    &.grey { background: ${color.mGrey}; }

    @media (max-width: 992px) {
        text-align: center;
        min-width: 40px;
        height: 40px;
        margin: 0;
        padding: 0;
        display: table-cell;
        vertical-align: middle;

        &.sub_nav_active {
            background-color: ${color.primaryColorMedium};

            &.primary { background: ${color.primaryColorLight}; }
            &.red { background: ${color.mRedLight}; }
            &.orange { background: ${color.mOrangeLight}; }
            &.yellow { background: ${color.mYellow}; }
            &.green { background: ${color.mGreenLight}; }
            &.mint { background: ${color.mMintLight}; }
            &.aqua { background: ${color.mAquaLight}; }
            &.blue { background: ${color.mBlueLight}; }
            &.purple { background: ${color.mPurpleLight}; }
            &.pink { background: ${color.mPinkLight}; }
            &.ocher { background: ${color.mOcher}; }
            &.grey { background: ${color.mGreyLight}; }
        }
    }
`;
export const SubNavigation = styled.div`
    background: ${color.primaryColor};
    padding: 10px 0;
    text-align: left;
    height: 50px;
    .sub_nav_wrapper {
        max-width: 2000px;
        margin: 0 auto;
    }
    a {
        color: ${color.white};
        font-weight: 400;
        font-size: ${font.mFontSize};
        text-transform: initial;
    }
`;
export const SubNavigationLink = styled.div`
    display: inline-block;
    text-align: center;
    min-width: 80px;
    padding: 0 10px;

    &:first-child { padding-top: 10px; }
    
    &.sub_nav_active {
        a button i {
            background: ${color.secondaryColorLight};
            color: ${color.black};
        }
    }
`;
export const UserOptions = styled.div`
    width: 100%;
    position: absolute;
    bottom: 10px;
    text-align: center;
`;
export const UserBox = styled.div`
    .user_init {
        background: ${color.primaryColor};
        width: 30px;
        height: 30px;
        display: inline-block;
        line-height: 2.1;
        text-align: center;
        color: ${color.white};
        font-weight: 500;
        ${set.radius100}
    }
`
export const UserBtn = styled.button`
    border: none;
    background: transparent;
    padding: 0;
    margin: 0 auto;
    
    .user_initials_btn {
        background: ${color.primaryColor};
        color: ${color.white};
        text-transform: uppercase;
        font-size: ${font.xsFontSize};
        padding: 0;
        height: 30px;
        width: 30px;
        display: inline-block;
        font-weight: 500;
        vertical-align: middle;
        padding-top: 6px;
        text-align: center;
        ${set.radius100}
        ${set.defaultShadow}
    }
    .user_arrow_btn {
        font-size: ${font.xsFontSize};
        padding-left: 2px;
        vertical-align: middle;
    }
    &:focus .user_initials_btn { ${set.hardShadow} }
`;
export const UserBtnNav = styled.div`
    display: none;
    position: absolute;
    min-width: 200px;
    max-width: 300px;
    text-align: left;
    background-color: ${color.white};
    border: 1px solid rgba(0,0,0,.15);
    left: 65px;
    bottom: 0px;
    ${set.radius6px}
    ${set.hardShadow}

    &.dropdown_active {
        display: block;
        z-index: 5;
    }
    .user_nav_before:before {
        content: '';
        height: 12px;
        border-style: solid;
        border-width: 11px 10px 11px 0;
        border-color: transparent #ccc transparent transparent;
        position: absolute;
        left: -10px;
        bottom: 1px;
    }
    .user_nav_before:after {
        content: '';
        height: 10px;
        border-style: solid;
        border-width: 10px 9px 10px 0;
        border-color: transparent ${color.white} transparent transparent;
        position: absolute;
        left: -9px;
        bottom: 2px;
    }
    .user_nav_header {
        padding: 10px 10px 0 10px;
        white-space: nowrap;
        .user_initials {
            background: ${color.primaryColor};
            color: ${color.white};
            text-transform: uppercase;
            font-size: ${font.xsFontSize};
            line-height: 2.4;
            padding: 0;
            height: 30px;
            width: 30px;
            display: inline-block;
            font-weight: 500;
            vertical-align: middle;
            text-align: center;
            ${set.radius100}
        }
        .user_name {
            font-size: ${font.sFontSize};
            color: ${color.black};
            padding-left: 5px;
            ${set.noWrap}
            max-width: 250px;
            display: inline-block;
            vertical-align: middle;
        }
    }
    .dropdown_link {
        white-space: nowrap;
        font-size: ${font.xsFontSize};
        display: block;
        border-top: ${color.greyLight} solid 1px;
        // padding: 10px;

        ${set.transitionAll}

        color: ${color.grey};
        cursor: pointer;
        &:hover {
            background-color: ${color.greyLight};
        }
        i {
            font-size: ${font.lFontSize};
            line-height: 9px;
        }
        a {
            color: ${color.grey};
            display: block;
            padding: 10px;
            &:hover {
                text-decoration: none;
            }
    
            i {
                font-size: ${font.lFontSize};
                line-height: 9px;
            }
        }
        .logout_link {
            color: ${color.grey};
            display: block;
            padding: 10px;
            &:hover {
                text-decoration: none;
            }
    
            i {
                font-size: ${font.lFontSize};
                line-height: 9px;
            }
        }
        svg {
            width: 15px;
            height: 15px;
            vertical-align: text-bottom;
            margin-right: 4px;
        }
    }
`;