import React, { Component } from "react";
import Switch from "react-switch";

import * as Styled from "./style";
//import * as palette from '../../style/Variables.js';


class StyledSwitch extends Component {
    constructor(props) {
      super();
      this.state = { checked: props.value ? props.value : false };
      this.handleChange = this.handleChange.bind(this);
    }
   
    handleChange(checked) {
      this.setState({ checked });
      this.props.onChange(checked);
    }
   
    render() {
      return (
          <Styled.Switch>
            <label htmlFor="normal-switch">
            <Switch
                className="react-switch"
                id="icon-switch"
                checked={this.state.checked}
                onChange={this.handleChange}
                onColor="#7b75ec"
                offColor="#55c0e9"
                onHandleColor="#fff"
                handleDiameter={22}
                height={30}
                width={this.props.width}
                uncheckedIcon={
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    color: "#fff",
                    textAlign: 'left',
                    position: 'absolute',
                    right: 10
                    }}
                >
                    {this.props.leftValue}
                </div>
                }
                checkedIcon={
                <div
                    style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 15,
                    color: "#fff",
                    textAlign: 'right',
                    position: 'absolute',
                    left: 10
                    }}
                >
                    {this.props.rightValue}
                </div>
                }
            />
            </label>
        </Styled.Switch>
      );
    }
  }

export default StyledSwitch;