import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from '../../components/button/Button';

const LogoutModal = ({ title, question, close, action }) => {
  const ref = useRef(null)
  const [elementHeight, setElementHeight] = useState(0)
  const [halfWindowHeight, setHalfWindowHeight] = useState(0)
  const [windowHeight, setWindowHeight] = useState(0)

  useEffect(() => {
    setWindowHeight(window.innerHeight - 107)
    setHalfWindowHeight(window.innerHeight / 2)
    setElementHeight(ref.current.clientHeight)
  }, [])
  //console.log('Window:', windowHeight)
  //console.log('Half window:', halfWindowHeight)
  //console.log('Element', elementHeight)
    
  return (
    <div>
      <Modal show={true} onHide={close} className={elementHeight <= halfWindowHeight ? 'small_modal' : ''}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={elementHeight <= windowHeight ? 'hidden_scroll' : ''}>
          <div ref={ref}>
            {question}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button actionClick={close} btnText={'No'} bgd={'white'}></Button>
          <Button actionClick={action} btnText={'Yes'} bgd={'secondary'}></Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
  
export default LogoutModal;