const initialState = {
    projects: '',
    status: [],
    priority: [],
    currentView: '',
    projectItemNotification: [],
    lastProjectItemNotification: {}
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_PROJECTS': 
            return { ...state, projects: action.payload }
        case 'SET_STATUS': 
            return { ...state, status: action.payload }
        case 'SET_PRIORITY': 
            return { ...state, priority: action.payload }
        case 'CURRENT_VIEW': 
            return { ...state, currentView: action.payload }
        case 'PROJECT_ITEM_NOTIFICATION': 
            return { ...state, projectItemNotification: action.payload }
        case 'LAST_PROJECT_ITEM_NOTIFICATION': 
            return { ...state, lastProjectItemNotification: action.payload }
        case 'LOGOUT':
                return { 
                    ...initialState,
                    projectItemNotification: state.projectItemNotification,
                    lastProjectItemNotification: state.lastProjectItemNotification,
                }
        default: {
            return state;
        }
    }
}

export default projectReducer;