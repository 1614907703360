import _ from 'lodash';

const T = (key, strings) => {
    let find = _.find(strings, (t) => { return t.key === key })

    if(find !== undefined && find.value !== '' && find.value !== null) {
        return find.value
    } else {
        return key
    }
}

export default T;