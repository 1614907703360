import { combineReducers } from 'redux';
import appReducer from './reducers/appReducer';
import userReducer from './reducers/userReducer';
import exampleTicketReducer from './reducers/exampleTicketReducer';
import costBookReducer from './reducers/costBookReducer';
import surveyReducer from './reducers/surveyReducer';
import projectReducer from './reducers/projectReducer';
import dmsReducer from './reducers/dmsReducer';

export default combineReducers({
  app: appReducer,
  user: userReducer,
  exampleTicket: exampleTicketReducer,
  costBook: costBookReducer,
  survey: surveyReducer,
  project: projectReducer,
  dms: dmsReducer
});
