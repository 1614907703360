import * as color from '../Variables/Colors';
//import * as font from '../Variables/Fonts';

// Element shadow
export const defaultShadow = {
    webkitBoxShadow: '1px 1px 2px 0px rgba(33,33,33,0.6)',
    mozBoxShadow: '1px 1px 2px 0px rgba(33,33,33,0.6)',
    msBoxShadow: '1px 1px 2px 0px rgba(33,33,33,0.6)',
    oBoxShadow: '1px 1px 2px 0px rgba(33,33,33,0.6)',
    boxShadow: '1px 1px 2px 0px rgba(33,33,33,0.6)',
};
export const hardShadow = {
    webkitBoxShadow: '2px 2px 2px 0px rgba(33,33,33,0.6)',
    mozBoxShadow: '2px 2px 2px 0px rgba(33,33,33,0.6)',
    msBoxShadow: '2px 2px 2px 0px rgba(33,33,33,0.6)',
    oBoxShadow: '2px 2px 2px 0px rgba(33,33,33,0.6)',
    boxShadow: '2px 2px 2px 0px rgba(33,33,33,0.6)',
};
export const noShadow = {
    webkitBoxShadow: 'none',
    mozBoxShadow: 'none',
    msBoxShadow: 'none',
    oBoxShadow: 'none',
    boxShadow: 'none',
};

// Transition
export const transitionAll = {
    webkitTransition: 'all 0.2s ease-in-out',
    mozTransition: 'all 0.2s ease-in-out',
    msTransition: 'all 0.2s ease-in-out',
    oTransition: 'all 0.2s ease-in-out',
    transition: 'all 0.2s ease-in-out',
};

// Border radius
export const radius100 = {
    webkitBorderRadius: '100%',
    mozBorderRadius: '100%',
    msBorderRadius: '100%',
    oBorderRadius: '100%',
    borderRadius: '100%',
};
export const radius60px = {
    webkitBorderRadius: '60px',
    mozBorderRadius: '60px',
    msBorderRadius: '60px',
    oBorderRadius: '60px',
    borderRadius: '60px',
};
export const radius6px = {
    webkitBorderRadius: '6px',
    mozBorderRadius: '6px',
    msBorderRadius: '6px',
    oBorderRadius: '6px',
    borderRadius: '6px',
};
export const radius4pxTop = {
    webkitBorderTopLeftRadius: '4px',
    mozBorderTopLeftRadius: '4px',
    msBorderTopLeftRadius: '4px',
    oBorderTopLeftRadius: '4px',
    borderTopLeftRadius: '4px',

    webkitBorderTopRightRadius: '4px',
    mozBorderTopRightRadius: '4px',
    msBorderTopRightRadius: '4px',
    oBorderTopRightRadius: '4px',
    borderTopRightRadius: '4px',
};
export const radius4pxBottom = {
    webkitBorderBottomLeftRadius: '4px',
    mozBorderBottomLeftRadius: '4px',
    msBorderBottomLeftRadius: '4px',
    oBorderBottomLeftRadius: '4px',
    borderBottomLeftRadius: '4px',

    webkitBorderBottomRightRadius: '4px',
    mozBorderBottomRightRadius: '4px',
    msBorderBottomRightRadius: '4px',
    oBorderBottomRightRadius: '4px',
    borderBottomRightRadius: '4px',
};

// Button
export const defaultBtn = {
    backgroundColor: color.white,
    color: color.black,
    lineHeight: '1',
    display: 'inline-block',
    position: 'relative',
    height: '30px',
    whiteSpace: 'nowrap',
    border: color.grey + ' 1px solid',
    verticalAlign: 'middle',
};

// Color
export const setColor = {
    color: color.black,

    '&.success': { color: color.success },
    '&.danger': { color: color.danger },
    '&.warning': { color: color.warning },
    '&.primary': { color: color.primaryColor },
    '&.secondary': { color: color.secondaryColor },
    '&.info': { color: color.info },
    '&.brand': { color: color.brand },
    '&.white': { color: color.white },
    '&.grey': { color: color.grey },
    '&.black': { color: color.black }
};

// Background
export const setBgd = {
    color: color.black,
    
    '&.success': { backgroundColor: color.success },
    '&.danger': { backgroundColor: color.danger },
    '&.warning': { backgroundColor: color.warning },
    '&.primary': { backgroundColor: color.primaryColor },
    '&.secondary': { backgroundColor: color.secondaryColor },
    '&.info': { backgroundColor: color.info },
    '&.brand': { backgroundColor: color.brand },
    '&.white': { backgroundColor: color.white },
    '&.grey': { backgroundColor: color.grey },
    '&.black': { backgroundColor: color.black }
};

// Background bgd
export const setBgdBGD = {
    color: color.black,
    
    '&.success': { backgroundColor: color.successBgd },
    '&.danger': { backgroundColor: color.dangerBgd },
    '&.warning': { backgroundColor: color.warningBgd },
    '&.primary': { backgroundColor: color.primaryColorBgd },
    '&.secondary': { backgroundColor: color.secondaryColorBgd },
    '&.info': { backgroundColor: color.infoBgd },
    '&.brand': { backgroundColor: color.brandBgd },
    '&.white': { backgroundColor: color.white },
    '&.grey': { backgroundColor: color.greyMedium },
    '&.black': { backgroundColor: color.black }
};

// Background light
export const setBgdLight = {
    color: color.black,

    '&.success': { backgroundColor: color.successLight },
    '&.danger': { backgroundColor: color.dangerLight },
    '&.warning': { backgroundColor: color.warningLight },
    '&.primary': { backgroundColor: color.primaryColorLight },
    '&.secondary': { backgroundColor: color.secondaryColorLight },
    '&.info': { backgroundColor: color.infoLight },
    '&.brand': { backgroundColor: color.brandLight },
    '&.white': { backgroundColor: color.white },
    '&.grey': { backgroundColor: color.greyLight },
    '&.black': { backgroundColor: color.greyMedium }
};

// Background and color
export const setBgdColor = {
    color: color.black,
    backgroundColor: color.white,

    '&.success': { backgroundColor: color.success, color: color.white },
    '&.danger': { backgroundColor: color.danger, color: color.white },
    '&.warning': { backgroundColor: color.warning, color: color.white },
    '&.primary': { backgroundColor: color.primaryColor, color: color.white },
    '&.secondary': { backgroundColor: color.secondaryColor, color: color.white },
    '&.info': { backgroundColor: color.info, color: color.white },
    '&.brand': { backgroundColor: color.brand, color: color.white },
    '&.white': { backgroundColor: color.white, color: color.black },
    '&.grey': { backgroundColor: color.grey, color: color.white },
    '&.black': { backgroundColor: color.black, color: color.white }
};

// Border, background and color
export const setBorderBgdColor = {
    color: color.black,
    backgroundColor: color.white,

    '&.success': { border: color.success + ' 1px solid', backgroundColor: color.success, color: color.white },
    '&.danger': { border: color.danger + ' 1px solid', backgroundColor: color.danger, color: color.white },
    '&.warning': { border: color.warning + ' 1px solid', backgroundColor: color.warning, color: color.white },
    '&.primary': { border: color.primaryColor + ' 1px solid', backgroundColor: color.primaryColor, color: color.white },
    '&.secondary': { border: color.secondaryColor + ' 1px solid', backgroundColor: color.secondaryColor, color: color.white },
    '&.info': { border: color.info + ' 1px solid', backgroundColor: color.info, color: color.white },
    '&.brand': { border: color.brand + ' 1px solid', backgroundColor: color.brand, color: color.white },
    '&.white': { border: color.black + ' 1px solid', backgroundColor: color.white, color: color.black },
    '&.grey': { border: color.grey + ' 1px solid', backgroundColor: color.grey, color: color.white },
    '&.black': { border: color.black + ' 1px solid', backgroundColor: color.black, color: color.white }
};

// No wrap
export const noWrap = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
};

// Pre-line
export const preLine = {
    whiteSpace: 'pre-line !important',
    overflow: 'visible !important',
    textOverflow: 'ellipsis',
};

// Scroll style
export const setScrollStyle = {
    color: color.black,
    '&::-webkit-scrollbar-track': { 
        borderRadius: '10px',
        backgroundColor: color.greyLight
    },
    '&::-webkit-scrollbar': { 
        width: '5px',
        height: '5px',
        backgroundColor: color.greyLight
    },
    '&::-webkit-scrollbar-thumb': { 
        borderRadius: '10px',
        backgroundColor: color.primaryColor
    },
};
