const initialState = {
    tickets: []
}

const exampleTicketReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_TICKETS': 
            return { tickets: action.payload }
        case 'LOGOUT':
            return { ...initialState }
        default: {
            return state;
        }
    }
}

export default exampleTicketReducer;