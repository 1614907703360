// Anywhere colors
export const primaryColor = '#55c0e9';
export const primaryColorLight = '#99d9f2';
export const primaryColorDark = '#408099';
export const primaryColorBgd = '#ccecf8';
export const primaryColorMedium = '#6cc8ec';
export const primaryColorTransparent = 'rgba(85, 192, 233, .2)';

export const secondaryColor = '#7b75ec';
export const secondaryColorLight = '#b0acf4';
export const secondaryColorDark = '#57539b';
export const secondaryColorBgd = '#d8d6f9';

// Basic colors
export const black = '#212121';
export const white = '#fff';
export const grey = '#4B515D';
export const greyMedium = '#bdbdbd';
export const greyLight = '#f6f6f6';

export const success = '#11c26d';
export const successLight = '#70daa7';
export const successDark = '#17824f';
export const successBgd = '#b8edd3';

export const warning = '#ffbb33';
export const warningLight = '#ffd685';
export const warningDark = '#a67d2c';
export const warningBgd = '#ffebc2';

export const danger = '#ff4444';
export const dangerLight = '#ff8f8f';
export const dangerDark = '#a63636';
export const dangerBgd = '#ffc7c7';

export const info = '#3e8ef7';
export const infoLight = '#8bbbfa';
export const infoDark = '#3262a1';
export const infoBgd = '#c5ddfd';

export const brand = '#716aca';
export const brandLight = '#aaa6df';
export const brandDark = '#514d86';
export const brandBgd = '#d5d3ef';

// Module colors
export const mRed = '#eb5463';
export const mRedLight = '#f76c82';
export const mRedDark = '#d94452';
export const mOrange = '#fa6c51';
export const mOrangeLight = '#fb836f';
export const mOrangeDark = '#e8553e';
export const mYellow = '#fdcd56';
export const mYellowLight = '#fbd277';
export const mYellowDark = '#f5b945';
export const mGreen = '#9ed26a';
export const mGreenLight = '#b4df80';
export const mGreenDark = '#89c053';
export const mMint = '#46ceac';
export const mMintLight = '#61ddbc';
export const mMintDark = '#35ba9b';
export const mAqua = '#4fc0e8';
export const mAquaLight = '#66d4f1';
export const mAquaDark = '#3aadd9';
export const mBlue = '#5e9cea';
export const mBlueLight = '#73b0f4';
export const mBlueDark = '#4a88da';
export const mPurple = '#ac92ea';
export const mPurpleLight = '#b3a4ee';
export const mPurpleDark = '#9579da';
export const mPink = '#eb86be';
export const mPinkLight = '#f199ce';
export const mPinkDark = '#d56fac';
export const mOcher = '#efc7a4';
export const mOcherLight = '#f4d0b5';
export const mOcherDark = '#e3b692';
export const mGrey = '#636c77';
export const mGreyLight = '#a9b0bb';
export const mGreyDark = '#424852';