
import React from 'react';

import * as IconsFa from "react-icons/fa";
import * as IconsIo from "react-icons/io";
import * as IconsMd from "react-icons/md";
import * as IconsTi from "react-icons/ti";
import * as IconsGo from "react-icons/go";
import * as IconsGi from "react-icons/gi";
import * as IconsFi from "react-icons/fi";
import * as IconsWi from "react-icons/wi";
import * as IconsAi from "react-icons/ai";

// ICON
export const flatBell = <i className="flaticon-bell" />;
export const flatMap = <i className="flaticon-map-location" />;
export const flatNetwork = <i className="flaticon-network" />;
export const flatTruck = <i className="flaticon-truck" />;
export const flatStatistic = <i className="flaticon-statistics" />;
export const flatRoute = <i className="flaticon-route" />;
export const flatBox = <i className="flaticon-open-box" />;
export const flatDesktop = <i className="flaticon-imac" />;
export const flatFolder = <i className="flaticon-folder-2" />;
export const flatList = <i className="flaticon-list-1" />;
export const flatCoins = <i className="flaticon-coins" />;
export const flatInterface = <i className="flaticon-interface-2" />;
export const flatApp = <i className="flaticon-app" />;
export const flatWeb = <i className="flaticon-web" />;
export const flatDiagram = <i className="flaticon-diagram" />;
export const flatProfile = <i className="flaticon-profile" />;
export const flatBgd = <i className="flaticon-background" />;
export const flatLineGraph = <i className="flaticon-line-graph" />;
export const flatLayers = <i className="flaticon-layers" />;
export const flatCode = <i className="flaticon-more-v4" />;
export const flatEye = <i className="flaticon-medical" />;
export const flatDashboard = <i className="flaticon-dashboard" />;
export const flatSquares = <i className="flaticon-squares-3" />;
export const flatList2 = <i className="flaticon-list" />;
export const flatUsers = <i className="flaticon-users" />;
export const flatSitemap = <i className="flaticon-map" />;
export const flatCalendar = <i className="flaticon-calendar" />;
export const flatQuestions = <i className="flaticon-questions-circular-button" />;

export const blackTie = <IconsFa.FaBlackTie/>;
export const aplications = <IconsFa.FaBuromobelexperte/>;
export const calendar = <IconsFa.FaRegCalendarAlt/>;
export const calendarCheck = <IconsFa.FaRegCalendarCheck/>;
export const calendarMinus = <IconsFa.FaRegCalendarMinus/>;
export const calendarPlus = <IconsFa.FaRegCalendarPlus/>;
export const calendarTimes = <IconsFa.FaRegCalendarTimes/>;
export const dotCircle = <IconsFa.FaRegDotCircle/>;
export const fire = <IconsFa.FaGripfire/>;
export const lightBulb = <IconsFa.FaMedapps/>;
export const caretRight = <IconsFa.FaCaretRight/>;
export const caretLeft = <IconsFa.FaCaretLeft/>;
export const home = <IconsFa.FaHome/>;
export const mapMarker = <IconsFa.FaMapMarkedAlt/>;

export const button = <IconsIo.IoMdRadioButtonOn/>;
export const arrowDown2 = <IconsIo.IoIosArrowRoundDown/>;
export const arrowLeft2 = <IconsIo.IoIosArrowRoundBack/>;
export const arrowRight2 = <IconsIo.IoIosArrowRoundForward/>;
export const arrowUp2 = <IconsIo.IoIosArrowRoundUp/>;

export const textFields = <IconsMd.MdTextFields/>;
export const tab = <IconsMd.MdTab/>;
export const apps = <IconsMd.MdApps/>;
export const list = <IconsMd.MdList/>;
export const input = <IconsMd.MdInput/>;
export const widgets = <IconsMd.MdWidgets/>;

export const numbers = <IconsTi.TiSortNumerically/>;

export const dashboard = <IconsGo.GoDashboard/>;
export const dot = <IconsGo.GoPrimitiveDot/>;
export const marker = <IconsGo.GoLocation/>;
export const lock = <IconsGo.GoLock/>;
export const email = <IconsGo.GoMail/>;
export const roadSign = <IconsGo.GoMilestone/>;
export const graduationCap = <IconsGo.GoMortarBoard/>;
export const note = <IconsGo.GoNote/>;
export const obd = <IconsGo.GoPlug/>;
export const rocket = <IconsGo.GoRocket/>;
export const search = <IconsGo.GoSearch/>;
export const star = <IconsGo.GoStar/>;
export const tag = <IconsGo.GoTag/>;
export const tools = <IconsGo.GoTools/>;

export const menu = <IconsFi.FiMenu/>;
export const text = <IconsFi.FiAlignLeft/>;
export const archive = <IconsFi.FiArchive/>;
export const bell = <IconsFi.FiBell/>;
export const box = <IconsFi.FiBox/>;
export const checkCircle = <IconsFi.FiCheckCircle/>;
export const check = <IconsFi.FiCheck/>;
export const checkSquare = <IconsFi.FiCheckSquare/>;
export const alertTriangle = <IconsFi.FiAlertTriangle/>;
export const arrowDown = <IconsFi.FiChevronDown/>;
export const arrowLeft = <IconsFi.FiChevronLeft/>;
export const arrowRight = <IconsFi.FiChevronRight/>;
export const arrowUp = <IconsFi.FiChevronUp/>;
export const arrowsDown = <IconsFi.FiChevronsDown/>;
export const arrowsLeft = <IconsFi.FiChevronsLeft/>;
export const arrowsRight = <IconsFi.FiChevronsRight/>;
export const arrowsUp = <IconsFi.FiChevronsUp/>;
export const clock = <IconsFi.FiClock/>;
export const copy = <IconsFi.FiCopy/>;
export const pencil = <IconsFi.FiEdit3/>;
export const eye = <IconsFi.FiEye/>;
export const filter = <IconsFi.FiFilter/>;
export const grid = <IconsFi.FiGrid/>;
export const heart = <IconsFi.FiHeart/>;
export const help = <IconsFi.FiHelpCircle/>;
export const image = <IconsFi.FiImage/>;
export const infoCircle = <IconsFi.FiInfo/>;
export const layers = <IconsFi.FiLayers/>;
export const link = <IconsFi.FiLink/>;
export const list2 = <IconsFi.FiList/>;
export const login = <IconsFi.FiLogIn/>;
export const logout = <IconsFi.FiLogOut/>;
export const mapPin = <IconsFi.FiMapPin/>;
export const map = <IconsFi.FiMap/>;
export const moreH = <IconsFi.FiMoreHorizontal/>;
export const moreV = <IconsFi.FiMoreVertical/>;
export const navigation = <IconsFi.FiNavigation/>;
export const paperclip = <IconsFi.FiPaperclip/>;
export const percent = <IconsFi.FiPercent/>;
export const call = <IconsFi.FiPhoneCall/>;
export const callForwarded = <IconsFi.FiPhoneForwarded/>;
export const callIncoming = <IconsFi.FiPhoneIncoming/>;
export const callMissed = <IconsFi.FiPhoneMissed/>;
export const phone = <IconsFi.FiPhone/>;
export const pieChart = <IconsFi.FiPieChart/>;
export const plus = <IconsFi.FiPlus/>;
export const plusCircle = <IconsFi.FiPlusCircle/>;
export const power = <IconsFi.FiPower/>;
export const refresh = <IconsFi.FiRefreshCw/>;
export const save = <IconsFi.FiSave/>;
export const settings = <IconsFi.FiSettings/>;
export const slash = <IconsFi.FiSlash/>;
export const smartphone = <IconsFi.FiSmartphone/>;
export const starOutline = <IconsFi.FiStar/>;
export const trash = <IconsFi.FiTrash2/>;
export const truck = <IconsFi.FiTruck/>;
export const userCheck = <IconsFi.FiUserCheck/>;
export const userMinus = <IconsFi.FiUserMinus/>;
export const userPlus = <IconsFi.FiUserPlus/>;
export const userX = <IconsFi.FiUserX/>;
export const user = <IconsFi.FiUser/>;
export const users = <IconsFi.FiUsers/>;
export const xCircle = <IconsFi.FiXCircle/>;
export const x = <IconsFi.FiX/>;
export const loader = <IconsFi.FiLoader/>;
export const database = <IconsFi.FiDatabase/>;


export const organizations = <IconsGi.GiOrganigram/>;
export const gavel = <IconsGi.GiGavel/>;
export const checkList = <IconsGi.GiChecklist/>;
export const keys = <IconsGi.GiHouseKeys/>;

export const thermometer = <IconsWi.WiThermometer/>;

export const home2 = <IconsAi.AiOutlineHome/>;
export const exclamation = <IconsAi.AiOutlineExclamation/>;
export const exclamationCircle = <IconsAi.AiOutlineExclamationCircle/>;
export const infoIcon = <IconsAi.AiOutlineInfo/>;
export const table = <IconsAi.AiOutlineTable/>;
export const calendar2 = <IconsAi.AiOutlineCalendar/>;

export const questCircle = <i className="las la-question-circle"></i>;
export const questMark = <i class="las la-question"></i>;
export const laBell = <i className="las la-bell"></i>;
export const laExcl = <i className="las la-exclamation"></i>;
export const laExclCircle = <i className="las la-exclamation-circle"></i>;
export const exclTriangle = <i className="las la-exclamation-triangle"></i>;
export const doubleDown = <i className="las la-angle-double-down"></i>;
export const doubleUp = <i className="las la-angle-double-up"></i>;
export const doubleRight = <i className="las la-angle-double-right"></i>;
export const doubleLeft = <i className="las la-angle-double-left"></i>;
export const angleDown = <i className="las la-angle-down"></i>;
export const angleUp = <i className="las la-angle-up"></i>;
export const angleRight = <i className="las la-angle-right"></i>;
export const angleLeft = <i className="las la-angle-left"></i>;
export const laArrowDown = <i className="las la-arrow-down"></i>;
export const laArrowUp = <i className="las la-arrow-up"></i>;
export const laArrowRight = <i className="las la-arrow-right"></i>;
export const laArrowLeft = <i className="las la-arrow-left"></i>;
export const arrowsH = <i className="las la-arrows-alt-h"></i>;
export const arrowsV = <i className="las la-arrows-alt-v"></i>;
export const exchange = <i className="las la-exchange-alt"></i>;
export const history = <i className="las la-history"></i>;
export const locationArrow = <i className="las la-location-arrow"></i>;
export const play = <i className="las la-play"></i>;
export const sort = <i className="las la-sort"></i>;
export const sortAbcDown = <i className="las la-sort-alpha-down"></i>;
export const sortAbcUp = <i className="las la-sort-alpha-up"></i>;
export const laRefresh = <i className="las la-sync"></i>;
export const circle = <i className="las la-circle"></i>;
export const bus = <i className="las la-bus"></i>;
export const car = <i className="las la-car"></i>;
export const carBattery = <i className="las la-car-battery"></i>;
export const gasPump = <i className="las la-gas-pump"></i>;
export const tachometer = <i className="las la-tachometer-alt"></i>;
export const laTruck = <i className="las la-truck"></i>;
export const building = <i className="las la-building"></i>;
export const laHome = <i className="las la-home"></i>;
export const university = <i className="las la-university"></i>;
export const school = <i className="las la-school"></i>;
export const addressCard = <i className="las la-address-card"></i>;
export const laArchive = <i className="las la-archive"></i>;
export const briefcase = <i className="las la-briefcase"></i>;
export const bullhorn = <i className="las la-bullhorn"></i>;
export const calculator = <i className="las la-calculator"></i>;
export const certificate = <i className="las la-certificate"></i>;
export const chartArea = <i className="las la-chart-area"></i>;
export const chartLine = <i className="las la-chart-line"></i>;
export const chartBar = <i className="las la-chart-bar"></i>;
export const chartPie = <i className="las la-chart-pie"></i>;
export const clipboard = <i className="las la-clipboard"></i>;
export const compass = <i className="las la-compass"></i>;
export const laCopy = <i className="las la-copy"></i>;
export const edit = <i className="las la-edit"></i>;
export const envelope = <i className="las la-envelope"></i>;
export const fax = <i className="las la-fax"></i>;
export const file = <i className="las la-file"></i>;
export const textFile = <i className="las la-file-alt"></i>;
export const folder = <i className="las la-folder"></i>;
export const folderOpen = <i className="las la-folder-open"></i>;
export const globe = <i className="las la-globe"></i>;
export const laPaperclip = <i className="las la-paperclip"></i>;
export const pen = <i className="las la-pen"></i>;
export const laPercent = <i className="las la-percent"></i>;
export const print = <i className="las la-print"></i>;
export const laSave = <i className="las la-save"></i>;
export const sitemap = <i className="las la-sitemap"></i>;
export const stickyNote = <i className="las la-sticky-note"></i>;
export const laTable = <i className="las la-table"></i>;
export const laTag = <i className="las la-tag"></i>;
export const tags = <i className="las la-tags"></i>;
export const tasks = <i className="las la-tasks"></i>;
export const thumbtack = <i className="las la-thumbtack"></i>;
export const wallet = <i className="las la-wallet"></i>;
export const laFire = <i className="las la-fire-alt"></i>;
export const laMap = <i className="las la-map"></i>;
export const mapMarked = <i className="las la-map-marked-alt"></i>;
export const mapSigns = <i className="las la-map-signs"></i>;
export const route = <i className="las la-route"></i>;
export const dollar = <i className="las la-dollar-sign"></i>;
export const laHeart = <i className="las la-heart"></i>;
export const leaf = <i className="las la-leaf"></i>;
export const piggyBank = <i className="las la-piggy-bank"></i>;
export const comment = <i className="las la-comment"></i>;
export const laPhone = <i className="las la-phone"></i>;
export const laGradCap = <i className="las la-graduation-cap"></i>;
export const barcode = <i className="las la-barcode"></i>;
export const qrcode = <i className="las la-qrcode"></i>;
export const laFilter = <i className="las la-filter"></i>;
export const userSecret = <i className="las la-user-secret"></i>;
export const mobile = <i className="las la-mobile"></i>;
export const wifi = <i className="las la-wifi"></i>;
export const desktop = <i className="las la-desktop"></i>;
export const download = <i className="las la-download"></i>;
export const upload = <i className="las la-upload"></i>;
export const plug = <i className="las la-plug"></i>;
export const paPower = <i className="las la-power-off"></i>;
export const server = <i className="las la-server"></i>;
export const pencilRuler = <i className="las la-pencil-ruler"></i>;
export const laTools = <i className="las la-tools"></i>;
export const euro = <i className="las la-euro-sign"></i>;
export const money = <i className="las la-money-bill"></i>;
export const laCalendar = <i className="las la-calendar"></i>;
export const laCalendarC = <i className="las la-calendar-check"></i>;
export const laCalendarM = <i className="las la-calendar-minus"></i>;
export const laCalendarP = <i className="las la-calendar-plus"></i>;
export const laCalendarT = <i className="las la-calendar-times"></i>;

export const laWeek = <i className="las la-calendar-week"></i>;
export const laDay = <i className="las la-calendar-day"></i>;

export const laClock = <i className="las la-clock"></i>;
export const hourglass = <i className="las la-hourglass-half"></i>;
export const stopwatch = <i className="las la-stopwatch"></i>;
export const laEye = <i className="las la-eye"></i>;
export const laLayers = <i className="las la-layer-group"></i>;
export const tint = <i className="las la-tint"></i>;
export const textLeft = <i className="las la-align-left"></i>;
export const font = <i className="las la-font"></i>;
export const laLink = <i className="las la-link"></i>;
export const laList = <i className="las la-list"></i>;
export const listCheck = <i className="las la-list-alt"></i>;
export const listNum = <i className="las la-list-ol"></i>;
export const laTrash = <i className="las la-trash-alt"></i>;
export const batteryEmpty = <i className="las la-battery-empty"></i>;
export const batteryFull = <i className="las la-battery-full"></i>;
export const battery = <i className="las la-battery-half"></i>;
export const key = <i className="las la-key"></i>;
export const suitcase = <i className="las la-suitcase"></i>;
export const laImage = <i className="las la-image"></i>;
export const sliders = <i className="las la-sliders-h"></i>;
export const ban = <i className="las la-ban"></i>;
export const laCheck = <i className="las la-check"></i>;
export const laCheckDouble = <i className="las la-check-double"></i>;
export const laCheckCircle = <i className="las la-check-circle"></i>;
export const laCheckSquare = <i className="las la-check-square"></i>;
export const cog = <i className="las la-cog"></i>;
export const cogs = <i className="las la-cogs"></i>;
export const ellipsisH = <i className="las la-ellipsis-h"></i>;
export const ellipsisV = <i className="las la-ellipsis-v"></i>;
export const language = <i className="las la-language"></i>;
export const laInfo = <i className="las la-info"></i>;
export const laInfoCircle = <i className="las la-info-circle"></i>;
export const laPlus = <i className="las la-plus"></i>;
export const laPlusCircle = <i className="las la-plus-circle"></i>;
export const laPlusSquare = <i className="las la-plus-square"></i>;
export const laSearch = <i className="las la-search"></i>;
export const searchLocation = <i className="las la-search-location"></i>;
export const laStar = <i className="las la-star"></i>;
export const times = <i className="las la-times"></i>;
export const laUser = <i className="las la-user"></i>;
export const userCircle = <i className="las la-user-circle"></i>;
export const laBox = <i className="las la-box"></i>;
export const clipboardCheck = <i className="las la-clipboard-check"></i>;
export const clipboardList = <i className="las la-clipboard-list"></i>;
export const directions = <i className="las la-directions"></i>;
export const polygon = <i className="las la-draw-polygon"></i>;
export const laGavel = <i className="las la-gavel"></i>;
export const laLocArrow = <i className="las la-location-arrow"></i>;
export const laMapMarker = <i className="las la-map-marker"></i>;
export const laMapPin = <i className="las la-map-pin"></i>;
export const road = <i className="las la-road"></i>;
export const laRocket = <i className="las la-rocket"></i>;
export const shopping = <i className="las la-shopping-cart"></i>;
export const ticket = <i className="las la-ticket-alt"></i>;
export const bullseye = <i className="las la-bullseye"></i>;
export const bolt = <i className="las la-bolt"></i>;
export const expand = <i className="las la-expand"></i>;
export const cubes = <i className="las la-cubes"></i>;
export const timeline = <i className="las la-stream"></i>;
export const laLock = <i className="las la-lock"></i>;
export const laColumns = <i className="las la-columns"></i>;
export const lightbulb = <i className="las la-lightbulb"></i>;
export const excel = <i className="las la-file-excel"></i>;
export const pdf = <i className="las la-file-pdf"></i>;
export const objectGroup = <i className="las la-object-group"></i>;
export const googlePlus = <i className="lab la-google-plus"></i>;
export const facebook = <i className="lab la-facebook-f"></i>;
export const navBar = <i className="lab las la-bars"></i>;
export const move = <i className="lab las la-arrows-alt"></i>;
export const radioBtn = <i className="lab las la-dot-circle"></i>;
export const laUsers = <i className="las la-user-friends"></i>;
export const genderless = <i className="las la-genderless"></i>;
export const instagram = <i className="lab la-instagram"></i>;
export const fileArchive = <i className="las la-file-archive"></i>;
export const fileAudio = <i className="las la-file-audio"></i>;
export const fileVideo = <i className="las la-file-video"></i>;
export const fileCode = <i className="las la-file-code"></i>;
export const filePdf = <i className="las la-file-pdf"></i>;
export const filePowerpoint = <i className="las la-file-powerpoint"></i>;
export const fileWord = <i className="las la-file-word"></i>;
export const js = <i className="lab la-js-square"></i>;
export const css = <i className="lab la-css3-alt"></i>;
export const html = <i className="lab la-html5"></i>;
export const react = <i className="lab la-react"></i>;
export const openBook = <i className="las la-book-open"></i>;
export const pager = <i className="las la-pager"></i>;
export const dragAndDrop = <i className="las la-exchange-alt rotate"></i>;
export const colorPalette = <i className="las la-palette"></i>;
export const sms = <i className="las la-sms"></i>;
export const share = <i className="las la-share-square"></i>;
