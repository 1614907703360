import { createGlobalStyle } from 'styled-components';

import * as set from './Variables/StyleSet';
import * as color from './Variables/Colors';
import * as font from './Variables/Fonts';

const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    body {
        font-size: ${font.titleFontSize};
        background: ${color.white};
        font-family: ${font.mainFont};
        width: 100%;
    }
    h1 {
        color: ${color.black};
        text-align: left;
        font-size: ${font.titleFontSize};
        line-height: 22px;
    }
    h2 {
        color: ${color.mGreyDark};
        text-align: left;
        font-size: ${font.sFontSize};
    }
    a {
        transition: all .5s;

        &:hover { text-decoration: none; }
        &:focus { outline: none; }
    }
    img {
        max-width: 100%;
        display: block;
    }
    button {
        border: none;
        background: transparent;

        &:focus { outline: none; }
        &:hover { opacity: 0.8; }
        &:disabled { opacity: 0.6; }
    }
    
    hr {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    
// MARGIN
    .mb-5 { margin-bottom: 5px !important; }
    .mb-10 { margin-bottom: 10px; }
    .mb-15 { margin-bottom: 15px; }
    .mb-20 { margin-bottom: 20px; }
    .mb-30 { margin-bottom: 30px; }

    .mt-5 { margin-top: 5px !important; }
    .mt-10 { margin-top: 10px; }
    .mt-15 { margin-top: 15px; }
    .mt-20 { margin-top: 20px; }
    .mt-30 { margin-top: 30px; }

    .mrl-0 { margin-right: 0px; margin-left: 0px; }
    .mrl-5 { margin-right: 5px; margin-left: 5px; }
    .mrl-10 { margin-right: 10px; margin-left: 10px; }
    .mrl-15 { margin-right: 15px; margin-left: 15px; }

    .mr-5 { margin-right: 5px !important; }
    .mr-10 { margin-right: 10px; }
    .mr-15 { margin-right: 15px; }

    .ml-5 { margin-left: 5px !important; }
    .ml-10 { margin-left: 10px; }
    .ml-15 { margin-left: 15px; }

// PADDING
    .p-0 { padding: 0; }

    .pb-5 { padding-bottom: 5px; }
    .pb-10 { padding-bottom: 10px; }
    .pb-15 { padding-bottom: 15px; }

    .pt-5 { padding-top: 5px !important; }
    .pt-10 { padding-top: 10px; }
    .pt-15 { padding-top: 15px; }

    .pl-1 { padding-left: 5px; }
    .pl-10 { padding-left: 10px; }
    .pl-15 { padding-left: 15px; }

    .pr-5 { padding-right: 5px; }
    .pr-10 { padding-right: 10px; }
    .pr-15 { padding-right: 15px; }

// SEPARATOR
    .col-xl-12.separator {
        border-right: none;
    }
    .separator {
        border-right: 1px solid #ebedf2;
        @media (max-width: 1199px) {
            border-right: none;
            border-bottom: 1px solid #ebedf2;
            padding-top: 15px;
            padding-bottom: 15px;
            margin-bottom: 15px;
        }
    }

// MODAL
    .modal-content {
        border-radius: 0;
        border: none;
        max-height: calc(100vh - 56px);

        ${set.setScrollStyle}
        
        .modal-header {
            width: 100%;
            padding: 10px;

            .modal-title {
                color: ${color.grey} !important;
                font-weight: 400;
                font-size: ${font.defaultFontSize};
            }
            button {
                padding: 10px;
                opacity: 1 !important;
                line-height: 20px;
                color: ${color.black};
                font-weight: 100;
                font-size: 28px;
                margin: 0;
                top: 0;
                right: 0;
                position: absolute;
            }
        }
        .modal-body {
            font-size: ${font.defaultFontSize};
            font-weight: 300;
            color: ${color.black};
            overflow-y: scroll;

            ${set.setScrollStyle}
        }
        .modal-footer {
            border-top: 1px solid #dee2e6;
            padding: 10px;
        }
    }
    .modal-dialog.full_modal {
        max-width: none;
        margin: 0;
        
        .modal-content { 
            height: 100vh;
            max-height: none;
        }
        .modal-body {
            overflow: auto;
        }
    }
    @media (max-width: 768px) {
        .modal-dialog {
            margin: 7px;
            height: calc(100vh - 14px);
            max-width: 100%;
        }
        .small_modal .modal-dialog {
            height: auto;
        }
        .modal-content {
            max-height: 100%;
        }
    }
    @media (max-height: 320px) {
        .small_modal .modal-dialog {
            height: scroll;
        }
    }

    .modal-footer>* { margin: 0; }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        font-size: ${font.sFontSize};
        font-family: ${font.mainFont};

        border: 1px solid ${color.black};
        -webkit-text-fill-color: ${color.black};
        -webkit-box-shadow: 0 0 0px 1000px ${color.white} inset;
        transition: background-color 5000s ease-in-out 0s;
    }

// FORM
    input[type="file"] {
        padding: 5px;
    }
    input[type="file" i]::-webkit-file-upload-button {
        background: ${color.secondaryColor};
        border: ${color.secondaryColor} 1px solid;
        color: #fff;
        font-size: 14px;
        line-height: 1;
        display: inline-block;
        position: relative;
        padding: 6px 15px 7px;
        min-width: 80px;
        height: 30px;
        width: auto;
        white-space: nowrap;

        ${set.radius60px}
        ${set.transitionAll}

        &:hover { opacity: 0.8; }
        &:focus { outline: none; box-shadow: 0 0 3pt 1pt ${color.secondaryColor}; }
    }
    .selected_item {
        background-color: ${color.greyLight};
        h1 { font-weight: 500; }
    }
    .required_field_msg {
        color: ${color.danger};
        font-weight: 400;
        font-size: ${font.xxsFontSize};
        text-align: right;
        line-height: 1.3;
    }

// MEDIA HELPERS
    @media (min-width: 993px) {
        .desktop_hidden {
            display: none;
        }
    }
    @media (max-width: 992px) {
        .mobile_hidden {
            display: none;
        }
    }
    @media (max-width: 768px) {
        .mobile_mb_10 {
            margin-bottom: 10px;
        }
    }

// SCROLL
    .hidden_scroll {
        overflow: visible !important;
    }
    .horizontal_scroll {
        overflow: scroll;
        overflow-y: hidden;
        ${set.setScrollStyle}
    }
    .vertical_scroll {
        overflow: scroll;
        overflow-x: hidden !important;
        ${set.setScrollStyle}
    }
    .vertical_auto {
        overflow-y: auto;
    }
    
// NAVIGATION
    .nav_scroll_h {
        overflow: scroll;
        overflow-y: hidden;
    }
    .nav_scroll_h::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: ${color.white};
    }
    .nav_scroll_h::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: ${color.white};
    }
    .nav_scroll_h::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: ${color.greyLight};
    }
    .nav_scroll_v {
        overflow: scroll;
        overflow-x: hidden !important;
    }
    .nav_scroll_v::-webkit-scrollbar-track {
        border-radius: 0px;
        background-color: ${color.white};
    }
    .nav_scroll_v::-webkit-scrollbar {
        width: 2px;
        height: 2px;
        background-color: ${color.white};
    }
    .nav_scroll_v::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: ${color.greyLight};
    }

// TEXT
    .text_right { text-align: right; }
    .text_left { text-align: left; }
    .text_center {
        text-align: center;
        display: block;
    }
    .text_nowrap {
        ${set.noWrap}
    }
    @media (max-width: 992px) {
        .text_mobile_center {
            margin-bottom: 10px;
            text-align: center;
        }
    }
    .text_danger {
        color: ${color.danger};
    }

// ALERT
    .Toastify__toast--success {
        background: ${color.success} !important;
        padding: 8px 10px !important;
    }
    .Toastify__toast--warning {
        background: ${color.warning} !important;
        padding: 8px 10px !important;
    }
    .Toastify__toast--error {
        background: ${color.danger} !important;
        padding: 8px 10px !important;
    }
    .Toastify__toast--info {
        background: ${color.white} !important;
        color: ${color.grey} !important;
        padding: 8px 10px !important;
        .Toastify__progress-bar {
            background-color: rgba(33, 37, 41, 0.3) !important;
        }
    }
    .alert_box {
        .alert_icon {
            font-size: ${font.xxxxlFontSize};
            line-height: 0.8;
            padding-right: 7px;
        }
        .alert_text {
            font-size: ${font.titleFontSize};
        }
    }
    .sweet-alert  {
        border-radius: 0 !important;
        width: auto !important;
        svg {
            font-size: 40px;
            text-align: center;
            width: 100%;
            color: ${color.grey} !important;
        }
        .text-muted svg {
            position: absolute;
            color: #6f727d;
            font-size: 17px;
            line-height: 1;
            z-index: 1;
            width: auto;
        }
        h2 {
            font-weight: 400 !important;
            font-size: ${font.xxxxlFontSize}; !important;
        }
        .text-muted.lead {
            min-height: auto !important;
        }
        a {
            box-shadow: none !important;
            font-size: ${font.defaultFontSize};
            padding: 6px 20px 7px !important;
            margin-bottom: 10px;
            line-height: 1;
            
            ${set.radius60px}            
            ${set.transitionAll}

            &:hover {
                opacity: 0.8;
                text-decoration: none;
            }
            svg {
                font-size: ${font.lFontSize};
                text-align: center;
                width: auto;
                color: ${color.white} !important;
            }
        }
        .btn-link {
            background-color: ${color.white} !important;
            border: ${color.black} 1px solid !important;
            color: ${color.black} !important;
        }
        .btn-primary {
            background-color: ${color.success} !important;
            border: ${color.success} 1px solid !important;
            color: ${color.white} !important;
        }
    }

// DATA
    .no_data {
        text-align: center;
        color: ${color.greyMedium};
        font-size: ${font.defaultFontSize};
    }

// DISPLAY
    .hidden_content { display: none !important; }
    .display_block { display: block; }
    .display_inline_block { display: inline-block; }

// FLOAT
    .float_right {
        float: right;
        text-align: right;
    }
    .float_left {
        float: left;
        text-align: left;
    }

// PRINT
    .hidden_except_print {
        display: none;
    }
    @media print {
        body * {
          visibility: hidden;
        }
        .hidden_except_print {
            display: block;
        }
        #section-to-print, #section-to-print * {
          visibility: visible;
        }
        #section-to-print {
          position: absolute;
          left: 0;
          top: 0;
          display: block;
        }
    }

// ALIGN
    .vertical-align {
        display: flex;
        align-items: center;
    }

// WIDTH
    .min_w_90 { min-width: 90px; }
    .min_w_110 { min-width: 110px; }
    .full_width { width: 100% !important; }
    .max_width_80 { max-width: 80px; }
    .max_width_150 { max-width: 170px; width: 100%; }

// TABLE
    .table_info {
        font-size: ${font.defaultFontSize};
        text-align: left;
    }

// CUSTOM COL
    .col-xs-5ths, .col-sm-5ths, .col-md-5ths, .col-lg-5ths {
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }
    .col-xs-5ths {
        width: 20%;
        float: left;
    }
    @media (min-width: 768px) {
        .col-sm-5ths {
            width: 20%;
            float: left;
        }
    }
    @media (min-width: 992px) {
        .col-md-5ths {
            width: 20%;
            float: left;
        }
    }
    @media (min-width: 1200px) {
        .col-lg-5ths {
            width: 20%;
            float: left;
        }
    }

// OTHER
    .react-grid-item > .react-resizable-handle::after {
        content: none;
    }
    .la-exchange-alt.rotate {
        -webkit-transform: rotate(20deg);
        -ms-transform: rotate(20deg);
        transform: rotate(90deg);
    }
    .path_link {
        color: ${color.secondaryColor};
        cursor: pointer;
    }
    .survey_modal_mid {
        top: calc(50% - 150px) !important;
        
        @media (min-width: 992px) {
            top: 0;
        }
    }
    .sticky_header {
        position: sticky;
        top: 0;
        background: white;
        z-index: 9;
    }
    .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd {
        background: transparent !important;
    }
    .indication_danger, .indication_warning {
        font-size: 10px;
        width: 14px;
        height: 14px;
        display: inline-block;
        line-height: 1.4;
        border-radius: 100%;
    }
    .indication_danger {
        color: ${color.white};
        background: ${color.danger};
        
    }
    .indication_warning {
        color: ${color.white};
        background: ${color.warning};
    }
`;

export default GlobalStyle;