import axios from '../../../utils/API';


// PROJECT API calls
// ------------------------------------------------------------------------------------------------
export async function getProjects(dataDB) {
    let response = await axios.get('/api/v1/project', { params: dataDB })
    return await response.data.data
}
export async function createProject(dataDB) {
    let response = await axios.post('/api/v1/project', dataDB )
    return await response.data.data
}
export async function updateProject(dataDB) {
    let response = await axios.post('/api/v1/project', dataDB )
    return await response.data.data
}
export async function deleteProject(dataDB) {
    let response = await axios.delete('/api/v1/project', { params: dataDB })
    return await response.data.data
}


export async function getProjectPhase(dataDB) {
    let response = await axios.get('/api/v1/project/phase', { params: dataDB })
    return await response.data.data
}
// create / edit same API
export async function createProjectPhase(dataDB) {
    let response = await axios.post('/api/v1/project/phase', dataDB )
    return await response.data.data
}
export async function deletePhase(dataDB) {
    let response = await axios.delete('/api/v1/project/phase', { params: dataDB })
    return await response.data.data
}


export async function getProjectItems(dataDB) {
    let response = await axios.get('/api/v1/project/item', { params: dataDB })
    return await response.data.data
}
export async function createProjectItem(dataDB) {
    let response = await axios.post('/api/v1/project/item', dataDB )
    return await response.data.data
}
export async function updateProjectItem(dataDB) {
    let response = await axios.post('/api/v1/project/item', dataDB )
    return await response.data.data
}
export async function deleteProjectItem(dataDB) {
    let response = await axios.delete('/api/v1/project/item', { params: dataDB })
    return await response.data.data
}

// PROJECT PROJECT RIGHTS
export async function getProjectRights(dataDB) {
    let response = await axios.get('/api/v1/project/rights', { params: dataDB })
    return await response.data.data
}

// PROJECT GROUP MAPPING
export async function getProjectGroupMapping(dataDB) {
    let response = await axios.get('/api/v1/project/group', { params: dataDB })
    return await response.data.data
}
export async function createProjectGroupMapping(dataDB) {
    let response = await axios.post('/api/v1/project/group', dataDB )
    return await response.data.data
}
export async function deleteProjectGroupMapping(dataDB) {
    let response = await axios.delete('/api/v1/project/group', { params: dataDB })
    return await response.data.data
}

// PROJECT PHASE GROUP MAPPING
export async function getProjectPhaseGroupMapping(dataDB) {
    let response = await axios.get('/api/v1/project/phase/group', { params: dataDB })
    return await response.data.data
}

export async function createProjectPhaseGroupMapping(dataDB) {
    let response = await axios.post('/api/v1/project/phase/group', dataDB )
    return await response.data.data
}

export async function deleteProjectPhaseGroupMapping(dataDB) {
    let response = await axios.delete('/api/v1/project/phase/group', { params: dataDB })
    return await response.data.data
}

// TASK
export async function getTasks(dataDB) {
    let response = await axios.get('/api/v1/project/task', { params: dataDB })
    return await response.data.data
}
// create / edit same API
export async function createTask(dataDB) {
    let response = await axios.post('/api/v1/project/task', dataDB )
    return await response.data.data
}
export async function deleteTask(dataDB) {
    let response = await axios.delete('/api/v1/project/task', { params: dataDB })
    return await response.data.data
}

// STATUS
export async function getStatus(dataDB) {
    let response = await axios.get('/api/v1/project/status', { params: dataDB })
    return await response.data.data
}

// PRIORITY
export async function getPriority(dataDB) {
    let response = await axios.get('/api/v1/project/priority', { params: dataDB })
    return await response.data.data
}

// NOTIFICATIONS
export async function getProjectNotification(dataDB) {
    let response = await axios.get('/api/v1/project/notification', { params: dataDB })
    return await response.data.data
}

// PROJECT ITEM NOTES
export async function getProjectItemNotes(dataDB) {
    let response = await axios.get('/api/v1/project/item/note', { params: dataDB })
    return await response.data.data
}

export async function createProjectItemNotes(dataDB) {
    let response = await axios.post('/api/v1/project/item/note', dataDB )
    return await response.data.data
}

export async function deleteProjectItemNotes(dataDB) {
    let response = await axios.delete('/api/v1/project/item/note', { params: dataDB })
    return await response.data.data
}

// ------------------------------------------------------------------------------------------------
